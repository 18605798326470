import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ParentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  width: 100%; /* Ensure it takes up full width of the parent */
`;

const Label = styled.label`
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.darkGray8};
  background: #ffffff08;
  padding: 5px;

  div.react-datepicker-wrapper {
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 60px;
  }

  @media (max-width: 480px) {
    height: 50px;
  }
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.black};

  svg {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const Input = styled(DatePicker)`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: ${({ theme }) => theme.black};

  /* Target the react-datepicker-wrapper */
  .react-datepicker-wrapper {
    display: inline-block; /* You can keep this if needed */
    padding: 0;
    border: 0;
    width: 400px; /* Ensure it takes full width */
  }

  input {
    width: 100% !important;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: ${({ theme }) => theme.gray25};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
const CustomDatePicker = ({
  icon,
  value,
  handleChange,
  label,
  placeholder,
}) => {
  const [date, setDate] = useState();

  const handleSelect = (value) => {
    setDate(value);
    handleChange(moment(value).format("Do MMM YYYY, h:mm A"));
  };

  return (
    <ParentContainer>
      <Label>{label}</Label>
      <Container>
        <Input
          selected={date}
          onChange={handleSelect}
          placeholder={placeholder}
          showTimeSelect // Enable time selection
          timeFormat="h:mm aa" // 12-hour format (can change to "HH:mm" for 24-hour format)
          timeIntervals={15} // Set intervals to 15 minutes (can be adjusted)
          dateFormat="MMMM d, yyyy h:mm aa" // Display both date and time in th
        />
        <Icon data-testid="icon">{icon}</Icon>
      </Container>
    </ParentContainer>
  );
};

export default CustomDatePicker;
