import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fillCreatePoolForm,
  getClaimedPoolS,
} from "../../../redux/actions/poolAction";
import { myClaimedRewardsCols, myClaimedRewardsRows } from "../tableUtil";
import CustomTable from "../../reusables/Table";
import Pagination from "../../reusables/Table/CustomPagination";

const ClaimedRewardsTable = () => {
  const user = useSelector((state) => state.persistedStore);
  const poolStore = useSelector((state) => state.PoolReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  // handle page number select
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPage(1);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchMyRewards = async () => {

    dispatch(getClaimedPoolS(user.address,user?.networkId, currentPage, pageLimit));
  };

  const claimReward = (pool) => {
    dispatch(
      fillCreatePoolForm({
        props: ["rewardToClaim"],
        value: pool,
      })
    );
    navigate("/claim-reward");
  };

  useEffect(() => {
    fetchMyRewards();
  }, [user.address, currentPage, pageLimit]);

  useEffect(() => {
    const processPools = async () => {
      if (poolStore.claimedPools) {
        const rows = await myClaimedRewardsRows(
          poolStore.claimedPools.items,
          claimReward,
          user.network
        );
        setTableRow(rows);
      }
    };

    processPools();
  }, [poolStore.claimedPools, user?.network]);

  return (
    <>
      {!poolStore?.gettingClaimedPools ? (
        // Render table here when data is available
        <>
          <CustomTable
            loading={false}
            data={{ columns: myClaimedRewardsCols, rows: tableRow }}
          />
          <Pagination
            totalItems={poolStore?.claimedPools?.meta?.totalItems}
            initialItemsPerPage={pageLimit} // Default items per page
            onPageChange={handlePageChange}
            handleItemsPerPageChange={handleLimitChange}
          />
        </>
      ) : (
        // <div>Table placeholder</div>
        <div>Loading...</div>
      )}
    </>
  );
};

export default ClaimedRewardsTable;
