import styled from "styled-components";

export const MultisendPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 100%;

  .blob-1 {
    position: absolute;
    bottom: 0px;
    left: -150px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 100%;
  }
`;

export const MultisendNav = styled.div`
  display: flex;
  align-items: center;
`;

export const RouteSwitchContainer = styled.div`
  display: flex;
  height: 30px;
  border-radius: 37px;
  align-items: center;
  opacity: 0px;
  background: #ffffff1f;
  gap: 10px;
  text-align: center;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.black};
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    background-color: ${({ theme }) => theme.greenLight};
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: auto;
    height: 30px;
    border: 1px solid ${({ theme }) => theme.greenLight};
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
    transition: background-color 0.3s, box-shadow 0.3s;

    @media (max-width: 992px) {
      width: 250px;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 14px;
    }

    @media (max-width: 576px) {
      width: 180px;
      padding: 10px 15px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
`;