import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  overflow-x: hidden;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  position: relative;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;
export const TableHeader = styled.thead`
  tr {
  }
`;
export const TableHead = styled.th`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  padding: 15px;
  background-color: #ffffff1f;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 5px;
  }
`;
export const TableRow = styled.tr`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  color: ${({ theme }) => theme.text};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.body};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.body2};
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 5px;
  }
`;
export const TableBody = styled.tbody``;
export const TableData = styled.td`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  color: ${({ theme }) => theme.text};
  padding: 15px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 5px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .paginationBttns {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    list-style-type: none;
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.text};

    @media (min-width: 768px) {
      gap: 10px;
      font-size: 16px;
    }
  }

  .paginationActive {
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    background: ${({ theme }) => theme.greenLight};
  }

  .prev,
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    list-style-type: none;
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 2px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.text};

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  .break-me {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  svg {
    font-weight: 500;
    cursor: pointer;
    font-size: 20px;
  }
`;

export const PageCount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

//
