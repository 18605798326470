import rewardPoolFactoryAbi from "../rewardPoolFactory.json";
import rewardPoolAbi from "../rewardPool.json";
import ERC20Abi from "../erc20.json";
import createContract from "./createContract";
import web3 from "web3";
import { toFixed } from "../helper";
import { ethers } from "ethers";

export async function getMyPools(walletAddress, network) {
  try {
    const rewardPoolFactory = await createContract(
      rewardPoolFactoryAbi,
      network.REWARDPOOL_FACTORY_PROXY_ADDRESS
    );

    const rewardPools = await rewardPoolFactory.methods
      .getRewardPoolsByUser(walletAddress)
      .call();
    console.log("Reward Pools:", rewardPools);
    return rewardPools;
  } catch (error) {
    return error;
  }
}

export async function getMyBySalt(poolUniqeIdentifier, network) {
  try {
    const rewardPoolFactory = await createContract(
      rewardPoolFactoryAbi,
      network.REWARDPOOL_FACTORY_PROXY_ADDRESS
    );

    const rewardPool = await rewardPoolFactory.methods
      .getUserPoolBySalt(poolUniqeIdentifier)
      .call();
    console.log("Reward Pools:", rewardPool);
    return rewardPool;
  } catch (error) {
    return error;
  }
}

export async function createRewardPool(
  walletAddress,
  network,
  decimals,
  eligibleAddresses,
  recipientAmounts,
  rewardAmounts,
  salt,
  duration,
  poolName,
  startTime,
  totalReward,
  token
) {
  let WEB3 = new web3(window.ethereum);

  try {
    // Step 1: Check for pending transactions
    const pendingNonce = await WEB3.eth.getTransactionCount(walletAddress, 'pending');
    const latestNonce = await WEB3.eth.getTransactionCount(walletAddress, 'latest');

    // Cancel all pending transactions, if any
    if (pendingNonce > latestNonce) {
      console.log(`Pending transactions detected. Latest nonce: ${latestNonce}, Pending nonce: ${pendingNonce}`);
      for (let nonce = latestNonce; nonce < pendingNonce; nonce++) {
        console.log(`Cancelling transaction with nonce: ${nonce}`);
        await cancelTransaction(walletAddress, nonce, 21000);
      }
    }

    // Step 2: Proceed with the contract interaction
    const rewardPoolFactory = await createContract(
      rewardPoolFactoryAbi,
      network.REWARDPOOL_FACTORY_PROXY_ADDRESS
    );

    const nonce = await WEB3.eth.getTransactionCount(walletAddress);
    
    const tokenContract = await createContract(ERC20Abi, token);
    let approvedAmount = 0;
    let calRewardAmount = []
    const num = Math.pow(10, Number(decimals));

    for (let i = 0; i < recipientAmounts.length; i++) {
      calRewardAmount.push(recipientAmounts[i] * num)
      approvedAmount += Number(recipientAmounts[i]);
    }

    console.log(calRewardAmount)
    const amtToApprove = num * approvedAmount;
    const value = toFixed(amtToApprove);

    // Fetch gas price and adjust for higher priority
    let gasPrice = await WEB3.eth.getGasPrice();
    console.log("Initial Gas Price:", gasPrice);
    
    // Step 3: Approve tokens
    const approveReceipt = await tokenContract.methods
      .approve(network.REWARDPOOL_FACTORY_PROXY_ADDRESS, value.toString())
      .send({ from: walletAddress, gasPrice: WEB3.utils.toWei((parseFloat(gasPrice) * 1.).toString(), 'wei')});

    console.log({ approveReceipt });

    // Step 4: If approve is successful, create the reward pool
    if (approveReceipt.status) {
      const gasEstimate = await rewardPoolFactory.methods
        .createRewardPool(
          eligibleAddresses,
          calRewardAmount,
          salt,
          duration,
          poolName,
          startTime,
          value,
          token
        )
        .estimateGas({
          from: walletAddress,
          gasPrice: WEB3.utils.toWei((parseFloat(gasPrice) * 1.2).toString(), 'wei')
        });

        const tx = await rewardPoolFactory.methods
        .createRewardPool(
          eligibleAddresses,
          calRewardAmount,
          salt,
          duration,
          poolName,
          startTime,
          value,
          token
        )
        .send({
          from: walletAddress,
          gas: gasEstimate,
          gasPrice: WEB3.utils.toWei((parseFloat(gasPrice) * 1.2).toString(), 'wei')
        });
    

      console.log("Reward Pools created successfully:", tx);
      const rewardPool = await getMyBySalt(salt, network);
      return { rewardPool, tx };
    }
  } catch (error) {
    console.log("An error occurred:", error.message || error);

    // General error handling (not retrying the process, as all pending transactions are already cancelled)
    throw new Error(`Failed to create reward pool: ${error.message || error}`);
  }
}

// Function to cancel the transaction
const cancelTransaction = async (account, nonce, gasPrice) => {
  let WEB3 = new web3(window.ethereum);

  const tx = {
      from: account,
      to: account,  // Sending to self with 0 value to cancel
      value: '0',
      gas: 21000,
      nonce: nonce,
      gasPrice: gasPrice,
  };

  return WEB3.eth.sendTransaction(tx);
}

export async function createRewardPoolNative(
  walletAddress,
  network,
  decimals,
  eligibleAddresses,
  rewardAmounts,
  salt,
  duration,
  poolName,
  startTime,
  totalReward
) {
  try {
    const rewardPoolFactory = await createContract(
      rewardPoolFactoryAbi,
      network.REWARDPOOL_FACTORY_PROXY_ADDRESS
    );
    let WEB3 = new web3(window.ethereum);
    console.log(eligibleAddresses,
        rewardAmounts,
        salt,
        duration,
        poolName,
        startTime,
        totalReward,
        ethers.ZeroAddress);

    const nonce = await WEB3.eth.getTransactionCount(walletAddress);

    let gasEstimate = await rewardPoolFactory.methods
      .createRewardPool(
        eligibleAddresses,
        rewardAmounts,
        salt,
        duration,
        poolName,
        startTime,
        totalReward,
        ethers.ZeroAddress
      )
      .estimateGas({
        from: walletAddress,
        nonce: nonce,
        value: totalReward,
      });

    let tx = await rewardPoolFactory.methods
      .createRewardPool(
        eligibleAddresses,
        rewardAmounts,
        salt,
        duration,
        poolName,
        startTime,
        totalReward,
        ethers.ZeroAddress
      )
      .send({
        from: walletAddress,
        gas: gasEstimate,
        value: totalReward,
      });
    // Check for returned events in the transaction receipt

    const rewardPool = await getMyBySalt(salt, network);
    console.log("Reward Pools created successfully:", tx, rewardPool);

    return { rewardPool, tx };
  } catch (error) {
    console.log(error);
    return error;
  }
}
// Retry logic for transactions
async function sendWithRetries(method, txParams, retries = 5) {
  for (let i = 0; i < retries; i++) {
    let WEB3 = new web3(window.ethereum);
    try {
      
      // Send transaction
      const result = await method.send(txParams);
      return result; // If successful, return result
    } catch (error) {
      console.log(error)
      if (error.message.includes("Transaction not mined within")) {
        console.log(`Retrying transaction, attempt ${i + 1}`);
        // Increase the gas price for retry
        const newGasPrice = WEB3.utils.toBN(txParams.gasPrice)
          .mul(WEB3.utils.toBN(120)) // increase gas price by 20%
          .div(WEB3.utils.toBN(100));
        txParams.gasPrice = newGasPrice.toString();
      } else {
        throw error; // Other errors should stop the process
      }
    }
  }
  throw new Error("Transaction failed after retries");
}

export async function claimReward(
  walletAddress,
  rewardPoolAddress,
  tokenAddress,
  fee
) {
  try {
    
    let WEB3 = new web3(window.ethereum);
    const rewardPoolContract = await createContract(
      rewardPoolAbi,
      rewardPoolAddress
    );
    const nonce  = await WEB3.eth.getTransactionCount(walletAddress, 'pending');
    const gasEstimate = await rewardPoolContract.methods
      .withdraw(tokenAddress)
      .estimateGas({
        from: walletAddress,
        value: fee
      });
      const gasPrice = await WEB3.eth.getGasPrice();
      console.log("Current Gas Price:", gasPrice);

      console.log(gasEstimate)
      const txParams = {
        from: walletAddress,
        value: fee,
        gas: gasEstimate,
        gasPrice: web3.utils.toWei((parseFloat(gasPrice) * 1.2).toString(), 'wei'), // Increase gas price by 20%
        nonce: nonce, // Use the pending nonce
      };
  
      // Send transaction with retry logic
      const rewardPools = await sendWithRetries(rewardPoolContract.methods.withdraw(tokenAddress), txParams);
  
    console.log("Reward Claimed:", rewardPools);
    return rewardPools;
  } catch (error) {
    console.log(JSON.stringify(error))
    throw error.data;
  }
}

export async function claimNativeReward(walletAddress, rewardPoolAddress) {
  try {
    const rewardPoolContract = await createContract(
      rewardPoolAbi,
      rewardPoolAddress
    );
    let WEB3 = new web3(window.ethereum);
    const nonce = await WEB3.eth.getTransactionCount(walletAddress);

    let balance = await WEB3.eth.getBalance(rewardPoolAddress);
    console.log(balance);

    const gasEstimate = await rewardPoolContract.methods
      .withdraw(ethers.ZeroAddress)
      .estimateGas({
        from: walletAddress,
        nonce: nonce,
      });
    // console.log( walletAddress,
    //     rewardPoolAddress,
    //     fee  )
    const rewardPools = await rewardPoolContract.methods
      .withdraw(ethers.ZeroAddress)
      .send({
        from: walletAddress,
        nonce: nonce,
        gas: gasEstimate,
      });
    console.log("Reward Claimed:", rewardPools);
    return rewardPools;
  } catch (error) {
    throw error
  }
}

export async function getTransactionFee(rewardPoolAddress, creatorAddress) {
  try {
    const rewardContract = await createContract(
      rewardPoolAbi,
      rewardPoolAddress
    );
    console.log(rewardContract);
    let transactionFee = await rewardContract.methods.getTransactionFee().call({
      from: creatorAddress, // Ensure you provide the user's address calling the function
      // gas: 300000, // Try increasing the gas limit
    });
    console.log("TransactionFee pooled:", transactionFee);
    return transactionFee;
  } catch (error) {
    console.log(error);
    return error;
  }
}
