import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTheme } from "styled-components";

const CustomLoader = () => {
  const [loadingDots, setLoadingDots] = useState(["."]);
  const theme = useTheme();

  useEffect(() => {
    setInterval(() => {
      if (loadingDots.length > 3) {
        setLoadingDots(["."]);
      } else {
        setLoadingDots(loadingDots.concat("."));
      }
    }, 700);
  }, []);
  return <div style={{ color: theme.black }}>Loading{loadingDots}</div>;
};

export default CustomLoader;
