import React from "react";
import styled from "styled-components";

const FilledButtonContainer = styled.button`
  background-color: ${({ theme }) => theme.greenLight};
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: auto;
  /* height: 34px; */
  text-align: center;
  border: 1px solid #38d8ab;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
  transition: background-color 0.3s, box-shadow 0.3s;

  @media (max-width: 992px) {
    width: 250px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 200px;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    width: 180px;
    padding: 10px 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }
`;

const FilledButton = ({ btnText, handleClick }) => {
  return (
    <FilledButtonContainer onClick={handleClick}>
      {btnText}
    </FilledButtonContainer>
  );
};

export default FilledButton;
