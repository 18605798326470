import createContract from "./createContract";
import payrollAbi from "../forwarderproxy.json";
import web3 from "web3";


export async function bulkSend(
  amounts,
  addresses,
  batchId,
  walletAddress,
  decimals,
  currencyAddress,
  fee,
  network
) {
  
  try {
    const bulkSenderContract = await createContract(
      payrollAbi,
      network.FORWARDER_PROXY_ADDRESS
    );
    let WEB3 = new web3(window.ethereum);

    console.log(network, "hey");
    const num = BigInt(Math.pow(10, Number(decimals)));

    let amountRecordsToSend = amounts.map(x => (num * BigInt(Math.floor(x * 10**18)) / BigInt(10**18)).toString());

    const nonce = await WEB3.eth.getTransactionCount(walletAddress)

    let gasEstimate = await bulkSenderContract.methods
    .distributeToken(batchId, currencyAddress, addresses, amountRecordsToSend)
    .estimateGas({
      from: walletAddress,
      value: fee.transactionFee,
      nonce: nonce
    });
    let result = await bulkSenderContract.methods
      .distributeToken(batchId, currencyAddress, addresses, amountRecordsToSend)
      .send({
        from: walletAddress,
        value: fee.transactionFee,
        gas: gasEstimate
      });

    return result;

    // if (result.status) {
    //   swal("Good job!", "Bulk Send Operation Processed Successfully!", "success");

    // } else {
    //   swal("That's a shame", "Really Sorry, This Bulk Send Operation Could Not Be Processed", "error");
    // }
  } catch (error) {
    return error;
    // swal(
    //   "That's a shame",
    //   `Really Sorry, This Bulk Send Operation Could Not Be Processed. Reason: ${error.message}`,
    //   "warning"
    // );
  }
}
export async function bulkSendNative(
  amounts,
  addresses,
  batchId,
  walletAddress,
  fee,
  network,
) {
  try {
    const bulkSenderContract = await createContract(
      payrollAbi,
      network.FORWARDER_PROXY_ADDRESS
    );
    const num = BigInt(Math.pow(10, Number(network.nativeCurrency.decimals)));
    const WEB3 = new web3(window.ethereum);

    // Convert amounts to integer values in wei
    const amountRecordsToSend = amounts.map(x => (num * BigInt(Math.floor(x * 10**18)) / BigInt(10**18)).toString());
    let totalAmount = BigInt(0);

    for (let i = 0; i < amounts.length; i++) {
      totalAmount += (num * BigInt(Math.floor(amounts[i] * 10**18)) / BigInt(10**18));
    }

    const nonce = await WEB3.eth.getTransactionCount(walletAddress);
    console.log(fee.transactionFee);

    // Ensure fee is also an integer and add it to totalAmount
    totalAmount += BigInt(fee.transactionFee) + BigInt(300000);
    console.log({ totalAmount: totalAmount.toString() }, nonce);

    const gasEstimate = await bulkSenderContract.methods
      .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
      .estimateGas({
        from: walletAddress,
        value: totalAmount.toString(),
        nonce: nonce,
      });

    const result = await bulkSenderContract.methods
      .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
      .send({
        from: walletAddress,
        value: totalAmount.toString(),
        gas: gasEstimate,
      });

    console.log({ result });

    return result;
  } catch (error) {
    console.log({ error });

    return error;
  }
}



// export async function bulkSendNative(
//   amounts,
//   addresses,
//   batchId,
//   walletAddress,
//   fee,
//   network,
// ) {
//   try {
//     const bulkSenderContract = await createContract(
//       payrollAbi,
//       network.forwarderProxyAddress
//     );
//     console.log({amounts,
//       addresses,
//       batchId,
//       walletAddress,
//       fee,
//       network})
//     var num = Math.pow(10, Number(network.nativeCurrency.decimals));
//     let WEB3 = new web3(window.ethereum);
//     let amountRecordsToSend = amounts.map((x) => (num * x).toString());
//     let totalAmount = 0
//     for (let i = 0; i < amounts.length; i++) {
//       totalAmount = totalAmount + (amounts[i] * num);
//     }

//     const nonce = await WEB3.eth.getTransactionCount(walletAddress)
//     console.log(fee.transactionFee)
//     totalAmount = totalAmount + Number(fee.transactionFee) + 300000
//     console.log({totalAmount}, nonce)

//     let gasEstimate = await bulkSenderContract.methods
//     .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//     .estimateGas({
//       from: walletAddress,
//       value: totalAmount,
//       nonce: nonce
//     });

//     let result = await bulkSenderContract.methods
//       .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//       .send({
//         from: walletAddress,
//         value: totalAmount,
//         gas: gasEstimate
//       });

//       console.log({result})

//     return result;
//   } catch (error) { 
//     console.log({error})

//     return error;
//   }
// }
