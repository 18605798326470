import styled from "styled-components";

export const ParentContainer = styled.div`
  .blob-1 {
    position: absolute;
    top: 0;
    right: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 1323px;
  }

  .blob-2 {
    position: absolute;
    top: 1465px;
    left: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 1323px;
  }

  .blob-3 {
    position: absolute;
    top: 2500px;
    right: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 1323px;
  }
  .blob-4 {
    position: absolute;
    top: 4300px;
    left: -185px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 1323px;
  }
  .blob-5 {
    position: absolute;
    top: 6000px;
    right: -185px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 1323px;
  }
`;


export const AirdropPoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  gap: 100px;
  z-index: 100;

  @media (min-width: 768px) {
    padding: 30px 50px;
  }

  .top-space {
    margin-top: 30px;
  }
`;