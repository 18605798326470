import React from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { FaCopy } from "react-icons/fa6";

const FooterContainer = styled.div`
  padding: 20px 140px;
  background-color: ${({ theme }) => theme.footerBg};
  color: ${({ theme }) => theme.text};
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px 10px;
  }
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    align-items: center;
  }
`;

const Logo = styled.img`
  margin-bottom: 20%;
  width: 120px;
`;

const FooterText = styled.p`
  margin: 5px 0;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

const Address = styled.span`
  background: #233748;
  padding: 10px 0;
  border-radius: 12px;
  color: #09bc8a;
  cursor: pointer;
`;

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const FooterLink = styled.p`
  margin: 5px 0;
  cursor: pointer;
`;

const FooterTextRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Footer = ({ currentTheme }) => {
  const theme = useTheme();

  return (
    <FooterContainer>
      <FooterRow>
        <FooterLeft>
          <Logo
            src={
              currentTheme === "light"
                ? "/assets/img/PNG/mitterpay_logo_light.png"
                : "/assets/img/PNG/footer_mitterpay_logo.png"
            }
            alt="Logo"
          />
          {/* <FooterText>Midas Contract Address</FooterText>
          <AddressContainer>
            <Address>
              <span>0xF1A786bC887Bb38f0478C24Ea00F4362CB68495c</span>
            </Address>
            <FaCopy
              onClick={() => {
                navigator.clipboard.writeText(
                  "0xF1A786bC887Bb38f0478C24Ea00F4362CB68495c"
                );
              }}
            />
          </AddressContainer> */}
        </FooterLeft>

        <FooterRight>
          <FooterLink>Home</FooterLink>
          <FooterLink>Features</FooterLink>
          <FooterLink>FAQ</FooterLink>
          <FooterLink>About</FooterLink>
        </FooterRight>
      </FooterRow>

      <FooterTextRow>
        <FooterText>Built by Mitter pay Team</FooterText>
        <FooterText>©2021 Mitterpay</FooterText>
        <FooterText>Privacy Policy</FooterText>
        <FooterText>Terms and Conditions</FooterText>
      </FooterTextRow>
    </FooterContainer>
  );
};

export default Footer;
