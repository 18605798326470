import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.body};
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 70%;
  margin: 10px auto;
  margin-bottom: 20px;
  z-index: 100;

  @media (max-width: 968px) {
    width: 80%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 90%;
    padding: 10px;
  }
`;

const FormCard = ({ children }) => {
  return <FormContainer>{children}</FormContainer>;
};

export default FormCard;
