import React, { useState } from "react";
import styled from "styled-components";

// Styled-components for Pagination
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const PageButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const PageButton = styled.button`
  background-color: ${(props) =>
    props.active ? ({ theme }) => theme.greenLight : "transparent"};
  color: ${(props) =>
    props.active ? "#fff" : ({ theme }) => theme.greenLight};
  border: 1px solid ${({ theme }) => theme.greenLight};
  padding: 2px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.greenLight};
    color: #fff;
  }

  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.greenLight};
    cursor: not-allowed;
  }
`;

const Dots = styled.span`
  margin: 0 5px;
  color: ${({ theme }) => theme.greenLight};
`;

const InfoWrapper = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.black};
  font-size: 14px;
`;

const Select = styled.select`
  margin-left: 10px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  border-radius: 4px;
  background-color: transparent;
  color: ${({ theme }) => theme.black};

  option {
    color: #000;
  }
`;

const Pagination = ({
  totalItems,
  initialItemsPerPage,
  onPageChange,
  handleItemsPerPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / initialItemsPerPage);
  const maxPagesToShow = 5;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page, initialItemsPerPage);
  };

  const getPageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const startRecord = (currentPage - 1) * initialItemsPerPage + 1;
  const endRecord = Math.min(currentPage * initialItemsPerPage, totalItems);

  return (
    <PaginationWrapper>
      {/* Info: Total records and items per page selection */}
      <InfoWrapper>
        Showing {startRecord} to {endRecord} of {totalItems} records.
        <Select value={initialItemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </Select>
      </InfoWrapper>
      <PageButtonWrapper>
        <PageButton
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          First
        </PageButton>
        <PageButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PageButton>

        {currentPage > maxPagesToShow && <Dots>...</Dots>}

        {getPageNumbers().map((page) => (
          <PageButton
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </PageButton>
        ))}

        {currentPage < totalPages - maxPagesToShow && <Dots>...</Dots>}

        <PageButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </PageButton>
        <PageButton
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          Last
        </PageButton>
      </PageButtonWrapper>
    </PaginationWrapper>
  );
};

export default Pagination;
