import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyPoolsApi } from "../../../redux/actions/poolAction";
import { myPoolsCols, myPoolsTableRows } from "../tableUtil";
import CustomTable from "../../reusables/Table";
import Pagination from "../../reusables/Table/CustomPagination";

const MyPoolsTable = () => {
  const [tableRow, setTableRow] = useState([]);
  const user = useSelector((state) => state.persistedStore); // Ensure user exists
  const poolStore = useSelector((state) => state.PoolReducer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  // handle page number select
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setCurrentPage(1);
  };

  // Fetch pools
  const fetchMyPools = async () => {

    dispatch(getMyPoolsApi(user.address, user?.networkId, currentPage, pageLimit));
  };

  useEffect(() => {
    fetchMyPools();
  }, [user.address, pageLimit, currentPage]);

  useEffect(() => {
    const processPools = async () => {
      if (poolStore.myPool) {
        const rows = await myPoolsTableRows(
          poolStore.myPool.items,
          user?.network
        );
        setTableRow(rows);
      }
    };

    processPools();
  }, [poolStore.myPool, user?.network]);

  return (
    <>
      {!poolStore.pullingPool ? (
        // Render table here when data is available
        <>
          <CustomTable
            loading={false}
            data={{ columns: myPoolsCols, rows: tableRow }}
          />
          <Pagination
            totalItems={poolStore?.myPool?.meta?.totalItems}
            initialItemsPerPage={pageLimit} // Default items per page
            onPageChange={handlePageChange}
            handleItemsPerPageChange={handleLimitChange}
          />
        </>
      ) : (
        // <div>Table placeholder</div>
        <div>Loading...</div>
      )}
    </>
  );
};

export default MyPoolsTable;
