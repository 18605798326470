import React, { useEffect } from "react";

import modalConstants from "../reusables/modal/modalConstants";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
// import Loader from "../reusables/loader";
// import SelectBlockchain from "../auth/SelectBlockchain";
import SelectNetwork from "../auth/SelectNetwork";
import styled from "styled-components";
import Masthead from "./Sections/MastHead";
import Achievements from "./Sections/Achievements";
import Features from "./Sections/Features";
import HowItWorks from "./Sections/HowItWorks";
import Compare from "./Sections/Comparism";
import FAQ from "./Sections/FAQs";
import Footer from "../reusables/Navs/Footer";
import Header from "./../reusables/Navs/Header";
import SupportedNetworks from "./Sections/SupportedNetworks";
import GetStarted from "./Sections/GetStarted";
import Testimony from "./Sections/Testimony";
import Partners from "./Sections/Partners";

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  gap: 100px;
  z-index: 100;

  @media (min-width: 768px) {
    padding: 30px 50px;
  }

  .top-space {
    margin-top: 30px;
  }
`;

const ParentContainer = styled.div`
  .blob-1 {
    position: absolute;
    top: 0;
    right: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 500px;
    height: 1323px;

    @media (min-width: 768px) {
      width: 1323px;
    }
  }

  .blob-2 {
    position: absolute;
    top: 1465px;
    left: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 500px;
    height: 1323px;

    @media (min-width: 768px) {
      width: 1323px;
    }
  }

  .blob-3 {
    position: absolute;
    top: 2500px;
    right: -348px;
    opacity: 0.7;
    z-index: 1;
    width: 500px;
    height: 1323px;

    @media (min-width: 768px) {
      width: 1323px;
    }
  }
  .blob-4 {
    position: absolute;
    top: 4300px;
    left: -185px;
    opacity: 0.7;
    z-index: 1;
    width: 500px;
    height: 1323px;

    @media (min-width: 768px) {
      width: 1323px;
    }
  }
  .blob-5 {
    position: absolute;
    top: 6000px;
    right: -185px;
    opacity: 0.7;
    z-index: 1;
    width: 500px;
    height: 1323px;

    @media (min-width: 768px) {
      width: 1323px;
    }
  }
`;

export const Landing = ({ currentTheme, themeToggler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.persistedStore);
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const connectToNetwork = async (network) => {
    dispatch(connectWallet(network));
  };

  useEffect(() => {
    if (auth && auth.isConnected) {
      navigate("/multisend");
    }
  }, [auth, navigate]);

  return (
    <ParentContainer style={{ position: "relative", width: "100%" }}>
      <img
        src="/assets/img/SVG/blob-1.svg"
        className="blob-1"
        alt="blob-1"
        style={{ zIndex: 1 }}
      />
      <img src="/assets/img/SVG/blob-2.svg" className="blob-2" alt="blob-2" />
      <img src="/assets/img/SVG/blob-3.svg" className="blob-3" alt="blob-3" />
      <img src="/assets/img/SVG/blob-4.svg" className="blob-4" alt="blob-4" />
      <img src="/assets/img/SVG/blob-5.svg" className="blob-5" alt="blob-5" />
      <LandingContainer>
        <Header currentTheme={currentTheme} themeToggler={themeToggler} />
        <Masthead />
        
        <Partners currentTheme={currentTheme} />
        <SupportedNetworks currentTheme={currentTheme} />
        <Achievements />
        <Features />
        <HowItWorks currentTheme={currentTheme} />
        <Compare />
        <GetStarted />
        {/* <Testimony /> */}
        {/* <FAQ /> */}
        <Footer currentTheme={currentTheme} />
      </LandingContainer>
      {modalPage === modalConstants.SelectNetwork ? (
        <SelectNetwork
          connectToNetwork={connectToNetwork}
          currentTheme={currentTheme}
        />
      ) : (
        ""
      )}
    </ParentContainer>
  );
};
