import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

// Partner images
const partnersData = [
  {
    light: "/assets/img/SVG/asset_chain_light.svg",
    dark: "/assets/img/SVG/asset_chain_dark.svg",
  },
  {
    light: "/assets/img/SVG/xend_light.svg",
    dark: "/assets/img/SVG/xend_dark.svg",
  },
  {
    light: "/assets/img/SVG/wicrypt_light.svg",
    dark: "/assets/img/SVG/wicrypt_dark.svg",
  },
  {
    light: "/assets/img/SVG/switchwallet_light.svg",
    dark: "/assets/img/SVG/switchwallet_dark.svg",
  },
  {
    light: "/assets/img/SVG/xend_bridge_light.svg",
    dark: "/assets/img/SVG/xend_bridge_dark.svg",
  },
];

// Container styling
const PartnersContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  background-color: ${({ theme }) => theme.gray26};
  border-radius: 46px;
  border: 1px solid #3a547a;
  height: auto;
  position: relative;
  padding: 0 20px; /* Add padding to the container */
`;

// Scrolling list container
const ScrollingList = styled.div`
  display: flex;
  transition: transform 0.1s linear; /* Quick transition for smoothness */
  padding: 20px;
  gap: 100px;
`;

// Individual partner image styling
const PartnerImage = styled.img`
  width: 200px;
  height: 70px;

  @media (max-width: 768px) {
    width: 150px;
    height: 50px;
  }

  @media (max-width: 480px) {
    width: 120px;
    height: 40px;
  }
`;

const Partners = ({ currentTheme }) => {
  const [partners, setPartners] = useState(partnersData);
  const scrollRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const imageWidth = 200; // Width of the image
  const scrollSpeed = 0.5; // Adjust scrolling speed here

  useEffect(() => {
    const scrollList = scrollRef.current;

    const moveImages = () => {
      setOffset((prevOffset) => {
        const newOffset = prevOffset - scrollSpeed; // Move left gradually

        // Check if the first image has fully moved out (width + gap)
        if (Math.abs(newOffset) >= imageWidth) {
          // Reset offset to create a seamless loop
          setPartners((prevPartners) => {
            const [first, ...rest] = prevPartners; // Remove the first image
            return [...rest, first]; // Add it to the end of the list
          });
          return newOffset + imageWidth; // Move the offset back by the width of an image
        }
        return newOffset;
      });
    };

    const interval = setInterval(moveImages, 16); // Move every ~16 ms (~60fps)

    return () => clearInterval(interval);
  }, []);

  return (
    <PartnersContainer>
      <ScrollingList
        ref={scrollRef}
        style={{ transform: `translateX(${offset}px)` }}
      >
        {/* Render current partners */}
        {partners.map((partner, index) => (
          <PartnerImage
            key={index}
            src={currentTheme === "light" ? partner.light : partner.dark}
            alt={`Partner ${index + 1} logo`}
          />
        ))}
      </ScrollingList>
    </PartnersContainer>
  );
};

export default Partners;
