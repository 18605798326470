import axios from "axios";
import _const from "../types";
import config from "../config";
import { addError } from "./error";

export const getNetworks = () => {
  return (dispatch) => {
    dispatch({
      type: _const.SET_GETTING_NETWORKS,
      payload: true,
    });
    axios
      .get( config.apiUrl + `networks`)
      .then((res) => {
        const response = res.data.data;
        const formattedRes = response.map((re) => {
          return {
            REWARDPOOL_FACTORY_PROXY_ADDRESS: re?.rewardpoolFactoryProxyAddress,
            FORWARDER_ADDRESS: re?.forwarderAddress,
            FORWARDER_PROXY_ADDRESS: re?.forwarderProxyAddress,
            active: re?.active,
            blockExplorerUrls: re?.blockExplorerUrls,
            chainId: re?.chainId,
            chainIdString: re?.chainIdString,
            chainName: re?.chainName,
            image: re?.image,
            nativeCurrency: {
              decimals: re?.nativeCurrencyDecimals,
              name: re?.nativeCurrencyName,
              symbol: re?.nativeCurrencySymbol,
            },
            rpcUrls: re?.rpcUrls,
          };
        });
        dispatch({
          type: _const.SET_NETWORKS,
          payload: formattedRes,
        });
        dispatch({
          type: _const.SET_GETTING_NETWORKS,
          payload: false,
        });
      })
      .catch((err) => {
        dispatch(addError(err));
        dispatch({
          type: _const.SET_GETTING_NETWORKS,
          payload: false,
        });
      });
  };
};
