import styled from "styled-components/macro";
// import { theme } from "../../theme";

export const Container = styled.div``;

export const BlockchainSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 50px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.lightGray};
  border-style: solid;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.gray7};

  img {
    display: none;
  }

  @media (min-width: 760px) {
    font-size: 16px;

    img {
      display: flex;
      width: auto;
      height: 25px
    }
  }
`;

export const NetworksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: auto;
  flex-wrap: wrap;
  padding: 10px;

  @media (min-width: 860px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
