import createContract from "./createContract";
import erc20Abi from "../erc20.json";

async function getBalance(currencyId, walletAddress) {
  let walletBalance;
  try {
    //Generate contract for approving mint
    const contract = await createContract(erc20Abi, currencyId);

    //Get the total amount of the token that the user has currently
    let balance = await contract.methods.balanceOf(walletAddress).call();
    walletBalance = Number(balance);
  } catch (error) {
    return error;
  }
  return walletBalance;
}

export async function getAccountInfo(tokenAddress, walletAddress) {
  let accountDetails;
  try {
    //Generate contract for approving mint
    const contract = await createContract(erc20Abi, tokenAddress);
    
    //Get the total amount of the token that the user has currently
    const balance = await contract.methods.balanceOf(walletAddress).call();
    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    let walletBalance = Number(balance) / Math.pow(10, Number(decimals));
    accountDetails = { name, symbol, decimals, walletBalance };
    console.log({ name, symbol, decimals, walletBalance });
  } catch (error) {
    return error;
  }
  return accountDetails;
}

export default getBalance;
