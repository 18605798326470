import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 140px;
  width: 100%;
  z-index: 1000;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;

  img {
    width: 140px;

    @media (max-width: 768px) {
      width: 120px;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
`;

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileNavItems = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: ${({ theme }) => theme.body};
  padding: 10px;
  position: absolute;
  top: 70px;
  right: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000000000;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
  }
`;

export const NavItem = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.greenLight};
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }

  .launch-button {
    color: ${({ theme }) => theme.greenLight};
    padding: 15px 20px;
    border-radius: 50px;
    cursor: pointer;
    width: 300px;
    border: 1px solid ${({ theme }) => theme.greenLight};
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 64.38px 93.36px 0px #00434652;
    transition: background-color 0.3s, box-shadow 0.3s;

    @media (max-width: 992px) {
      width: 250px;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 14px;
    }

    @media (max-width: 576px) {
      width: 180px;
      padding: 10px 15px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
`;

export const ThemeToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;

export const HamburgerIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

export const LaunchButton = styled.span`
  color: ${({ theme }) => theme.greenLight};
  padding: 15px 20px;
  border-radius: 50px;
  cursor: pointer;
  width: 300px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 64.38px 93.36px 0px #00434652;
  transition: background-color 0.3s, box-shadow 0.3s;

  @media (max-width: 992px) {
    width: 250px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 200px;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    width: 180px;
    padding: 10px 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }
`;

export const HeaderButton = styled.button`
  color: ${({ theme }) => theme.greenLight};
  background-color: ${({ theme }) => theme.body};
  padding: 5px 15px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: auto;
  border: 1px solid #38d8ab;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
  transition: background-color 0.3s, box-shadow 0.3s;

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    padding: 10px 15px;
  }
`;
