const _const = {
  // Theme
  SET_THEME: "SET_THEME",

  //Forms
  FILL_OUT_FORM: "FILL_OUT_FORM",
  EDIT_FORM: "EDIT_FORM",

  //Payroll
  GETTING_ALL_PAYROLL: "GETTING_ALL_PAYROLL",
  CREATING_PAYROLL_ERR: "CREATING_PAYROLL_ERR",
  ALL_PAYROLL: "ALL_PAYROLL",
  CREATING_PAYROLL: "CREATING_PAYROLL",
  GETTING_PAYROLL_RECIPIENTS: "GETTING_PAYROLL_RECIPIENTS",
  CREATING_PAYROLL_RECIPIENT: "CREATING_PAYROLL_RECIPIENT",
  ALL_PAYROLL_RECIPIENT: "ALL_PAYROLL_RECIPIENT",
  INITIATING_PAYMENT: "INITIATING_PAYMENT",
  INITIATING_PAYMENT_SUCCESS: "INITIATING_PAYMENT_SUCCESS",
  PAYROLL_CREATED: "PAYROLL_CREATED",
  RECIPIENT_CREATED: "RECIPIENT_CREATED",
  PAYROLLL_SEARCH_RESULT: "PAYROLLL_SEARCH_RESULT",
  ALL_PAYROLL_RECIPIENTS: "ALL_PAYROLL_RECIPIENTS",
  EXCEL_RECIPIENT: "EXCEL_RECIPIENT",
  DELETING_PAYROLL: "DELETING_PAYROLL",
  APPROVING_PAYMENT: "APPROVING_PAYMENT",
  SUCCESSFUL_PAYMENT: "SUCCESSFUL_PAYMENT",
  GETTING_PAYROLL_SUMMARY: "GETTING_PAYROLL_SUMMARY",
  ALL_PAYROLL_SUMMARY: "ALL_PAYROLL_SUMMARY",
  SETTING_REMINDER: "SETTING_REMINDER",
  SETTING_REMINDER_SUCCESS: "SETTING_REMINDER_SUCCESS",
  SELECTED_PAYROLL_DETAILS: "SELECTED_PAYROLL_DETAILS",
  SETTING_RESET_STATUS: "SETTING_RESET_STATUS",
  SETTING_RESET_STATUS_SUCCESS: "SETTING_RESET_STATUS_SUCCESS",
  TRANSACTION_FEES: "TRANSACTION_FEES",
  CREATING_PAYROLL_RECIPIENT_ERR: "CREATING_PAYROLL_RECIPIENT_ERR",

  //error
  ADD_ERROR: "ADD_ERROR",
  REMOVE_ERROR: "REMOVE_ERROR",

  //auth
  STORE_ADDRESS: "STORE_ADDRESS",
  REMOVE_ADDRESS: "REMOVE_ADDRESS",
  GET_USER: "GET_USER",
  SIGNATURE: "SIGNATURE",
  AUTHENTICATING_USER: "AUTHENTICATING_USER",
  CONNECT_APP_FAIL: "CONNECT_APP_FAIL",
  RESET_USER: "RESET_USER",

  //Quick Pay
  QUICKPAY_TOGGLE: "QUICKPAY_TOGGLE",
  QUICKPAY_RECIPIENT: "QUICKPAY_RECIPIENT",
  GET_QUICKSEND_RECIPIENT: "GET_QUICKSEND_RECIPIENT",
  GET_QUICKSEND_TRANSACTION: "GET_QUICKSEND_TRANSACTION",
  MADE_IMPORT: "MADE_IMPORT",
  INITIATING_QUICKSEND_PAYMENT: "INITIATING_QUICKSEND_PAYMENT",
  ALL_QUICKPAY_RECIPIENTS: "ALL_QUICKPAY_RECIPIENTS",

  //report
  GET_RECEPIENT_REPORT: "GET_RECEPIENT_REPORT",
  GETTING_TRANSACTION_HISTORY: "GETTING_TRANSACTION_HISTORY",

  //general
  QUICKPAY_TOGGLE: "QUICKPAY_TOGGLE",
  GET_CURRENCY: "GET_CURRENCY",
  SUCCESSFULL_ACTION: "SUCCESSFULL_ACTION",
  COPIED_TO_CLIPBOARD: "COPIED_TO_CLIPBOARD",
  CLEAR_DATA: "CLEAR_DATA",
  TOGGLE_MODAL: "TOGGLE_MODAL",

  //Currency
  CREATING_CURRENCY: "CREATING_CURRENCY",
  CURRENCY_CREATED_SUCCESSFULLY: "CURRENCY_CREATED_SUCCESSFULLY",
  CREATING_CURRENCY_ERROR: "CREATING_CURRENCY_ERROR",

  // multi_send
  STORE_MULTISEND_DETAILS: "STORE_MULTISEND_DETAILS",

  // networks
  SET_NETWORKS: "SET_NETWORKS",
  SET_GETTING_NETWORKS: "SET_GETTING_NETWORKS",
  //pool
  PULLING_POOL: "PULLING_POOL",
  SAVE_MY_POOL: "SAVE_MY_POOL",
  SAVE_MY_POOL_REWARDS: "SAVE_MY_POOL_REWARDS",
  FILL_CREATE_POOL_FORM: "FILL_CREATE_POOL_FORM",
  GETTING_POOL_BY_CREATOR: "GETTING_POOL_BY_CREATOR",
  GETTING_EXPIRED_POOL_BY_CREATOR: "GETTING_EXPIRED_POOL_BY_CREATOR",
  GETTING_ELIGIBLE_POOLS: "GETTING_ELIGIBLE_POOLS",
  GETTING_EXPIRED_ELIGIBLE_POOLS: "GETTING_EXPIRED_ELIGIBLE_POOLS",
  GETTING_CLAIMED_POOLS: "GETTING_CLAIMED_POOLS",
  SAVE_POOL_BY_CREATOR: "SAVE_POOL_BY_CREATOR",
  SAVE_EXPIRED_POOL_BY_CREATOR: "SAVE_EXPIRED_POOL_BY_CREATOR",
  SAVE_ELIGIBLE_POOLS: "SAVE_ELIGIBLE_POOLS",
  SAVE_EXPIRED_ELIGIBLE_POOLS: "SAVE_EXPIRED_ELIGIBLE_POOLS",
  SAVE_CLAIMED_POOLS: "SAVE_CLAIMED_POOLS",
};

export default _const;
