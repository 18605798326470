export const theme = {
  //Default colors
  //white: "#FFFFFF",

  //Text sizes
  textLg: "3rem",
  textSmd: "2.0rem",
  textMd: "1.5rem",
  textSm: "1.0rem",
  textXs: "0.7rem",
  textXXs: "0.5rem",

  //Text weight
  textNormal: 500,
  textBold: 600,
  textXBold: "bold",
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

// src/theme.js
export const lightTheme = {
  body: "#C9D5E7",
  body2: "#C9D5E7",
  text: "#0E1626",
  background: "#f0f0f0",
  primary: "#1a73e8",
  secondary: "#06babc",
  red: "red",
  green: "green",
  orange: "#ff9b44",
  lightBlue: "#a9baf3",
  darkBlue: "#344684",
  white: "#ffffff",
  black: "#000",
  lightGray: "#0E1626",
  gray1: "#757575",
  gray2: "#777",
  darkGray1: "#16191c",
  darkGray2: "#2e3840",
  translucentBlack1: "rgba(0, 0, 0, 0.2)",
  gray3: "#bbc4cc",
  gray4: "#ced4da",
  darkGray3: "#464e55",
  darkGray4: "#23272b",
  whiteSmoke: "#f9f9f9",
  gray5: "#393939",
  gray6: "#ddd",
  gray7: "#606060",
  teal: "#508991",
  blue: "#0c5bdc",
  greenLight: "#09bc8a",
  greenDark: "#0f3f32",
  transparent: "#ffffff",
  translucentBlack2: "rgba(0, 0, 0, 0.05)",
  translucentBlack3: "rgba(0, 0, 0, 0.08)",
  gray8: "#8b94a1",
  translucentBlack4: "rgba(0, 0, 0, 0.1)",
  translucentRed: "rgba(255, 92, 111, 0.2)",
  red1: "#f14668",
  translucentBlue: "rgba(92, 111, 255, 0.2)",
  blue1: "#4686f1",
  translucentGreen: "rgba(92, 255, 111, 0.2)",
  green1: "#46f168",
  gray9: "#999999",
  translucentBlack5: "rgba(0, 0, 0, 0.6)",
  darkGray5: "#172a3a",
  gray10: "#888",
  tealLight: "rgb(0 67 70 / 32%)",
  gray11: "#aaa",
  orange1: "#fd7e14",
  darkGray6: "#343a40",
  tomato: "tomato",
  green2: "#4caf50",
  gray12: "#e0e0e0",
  gray13: "#797979",
  translucentLightBlue: "rgba(169, 186, 243, 0.15)",
  blue2: "#2962ff",
  lightGray2: "#f7f7f7",
  lightGray3: "#e3e3e3",
  darkGray7: "#202e3a",
  blue3: "#007bff",
  gray14: "#9e9e9e",
  darkGray8: "#2e3439",
  translucentBlack6: "rgba(0, 0, 0, 0.5)",
  translucentBlack7: "rgba(0, 0, 0, 0.4)",
  purple: "#7460ee",
  green3: "#55ce63",
  blue4: "#009efb",
  orange2: "#ffbc34",
  red2: "#f62d51",
  gray15: "#8e8e8e",
  purple2: "#482fe9",
  green4: "#66e6c1",
  green5: "#8df7d9",
  green6: "#4ab657",
  green7: "#55ce63",
  blue5: "#009efb",
  blue6: "#028ee1",
  red3: "#e6294b",
  orange3: "#e9ab2e",
  red4: "#fc6075",
  blue7: "#4d8af0",
  blue8: "#0E1626",
  red5: "#e63c3c",
  lightGray4: "#dfdfdf",
  gray16: "#74767b",
  lightGray5: "#c2c6d1",
  green8: "#26af48",
  purple3: "#6c61f6",
  orange4: "#f39c12",
  translucentGray1: "rgba(194, 198, 209, .2)",
  translucentGreen1: "rgba(15, 183, 107, 0.12)",
  translucentPurple1: "rgba(108, 97, 246, .2)",
  translucentOrange1: "rgba(255, 152, 0, 0.12)",
  translucentGray2: "rgba(223, 223, 223, .2)",
  translucentGray3: "rgba(116, 118, 123, .2)",
  translucentWhite1: "rgba(255, 255, 255, .2)",
  translucentTeal1: "rgba(2, 182, 179, 0.12)",
  gray17: "#1db9aa",
  darkGray9: "#283447",
  purple4: "#6e00ff",
  gray18: "#333",
  gray19: "#6c757d",
  yellow: "#FEEB99",
  gray20: "#555",
  darkGray10: "#1f1f1f",
  gray21: "#a0a0a0",
  translucentBlack8: "rgba(0, 0, 0, 0.125)",
  gray22: "#BABABA",
  translucentTeal2: "rgba(0, 67, 70, 0.32)",
  gray23: "#656565",
  gray24: "#616161",
  gray25: "#bdbdbd",
  gray26: "#ffffff",
};

export const darkTheme = {
  body: "#041320",
  body2: "#0b1b36",
  text: "#f0f0f0",
  background: "#000",
  primary: "#0c5bdc",
  secondary: "#09bc8a",
  red: "tomato",
  green: "#4caf50",
  orange: "#fd7e14",
  lightBlue: "#bbc4cc",
  darkBlue: "#2e3840",
  white: "#fefefe",
  black: "#fff",
  lightGray: "#7F99AD",
  gray1: "#606060",
  gray2: "#777",
  darkGray1: "#16191c",
  darkGray2: "#2e3840",
  translucentBlack1: "rgba(0, 0, 0, 0.2)",
  gray3: "#ced4da",
  gray4: "#393939",
  darkGray3: "#464e55",
  darkGray4: "#23272b",
  whiteSmoke: "#f9f9f9",
  gray5: "#757575",
  gray6: "#ccc",
  gray7: "#ddd",
  
  teal: "#508991",
  blue: "#1a73e8",
  greenLight: "#09bc8a",
  greenDark: "#0f3f32",
  transparent: "transparent",
  translucentBlack2: "rgba(0, 0, 0, 0.05)",
  translucentBlack3: "rgba(0, 0, 0, 0.08)",
  gray8: "#8b94a1",
  translucentBlack4: "rgba(0, 0, 0, 0.1)",
  translucentRed: "rgba(255, 92, 111, 0.2)",
  red1: "#f14668",
  translucentBlue: "rgba(92, 111, 255, 0.2)",
  blue1: "#4686f1",
  translucentGreen: "rgba(92, 255, 111, 0.2)",
  green1: "#46f168",
  gray9: "#999999",
  translucentBlack5: "rgba(0, 0, 0, 0.6)",
  darkGray5: "#172a3a",
  gray10: "#888",
  tealLight: "rgb(0 67 70 / 32%)",
  gray11: "#aaa",
  orange1: "#fd7e14",
  darkGray6: "#343a40",
  tomato: "tomato",
  green2: "#4caf50",
  gray12: "#e0e0e0",
  gray13: "#797979",
  translucentLightBlue: "rgba(169, 186, 243, 0.15)",
  blue2: "#2962ff",
  lightGray2: "#f7f7f7",
  lightGray3: "#e3e3e3",
  darkGray7: "#202e3a",
  blue3: "#007bff",
  gray14: "#9e9e9e",
  darkGray8: "#2e3439",
  translucentBlack6: "rgba(0, 0, 0, 0.5)",
  translucentBlack7: "rgba(0, 0, 0, 0.4)",
  purple: "#7460ee",
  green3: "#55ce63",
  blue4: "#009efb",
  orange2: "#ffbc34",
  red2: "#f62d51",
  gray15: "#8e8e8e",
  purple2: "#482fe9",
  green4: "#66e6c1",
  green5: "#8df7d9",
  green6: "#4ab657",
  green7: "#55ce63",
  blue5: "#009efb",
  blue6: "#028ee1",
  red3: "#e6294b",
  orange3: "#e9ab2e",
  red4: "#fc6075",
  blue7: "#4d8af0",
  blue8: "#9cc8ec",
  red5: "#e63c3c",
  lightGray4: "#dfdfdf",
  gray16: "#74767b",
  lightGray5: "#c2c6d1",
  green8: "#26af48",
  purple3: "#6c61f6",
  orange4: "#f39c12",
  translucentGray1: "rgba(194, 198, 209, .2)",
  translucentGreen1: "rgba(15, 183, 107, 0.12)",
  translucentPurple1: "rgba(108, 97, 246, .2)",
  translucentOrange1: "rgba(255, 152, 0, 0.12)",
  translucentGray2: "rgba(223, 223, 223, .2)",
  translucentGray3: "rgba(116, 118, 123, .2)",
  translucentWhite1: "rgba(255, 255, 255, .2)",
  translucentTeal1: "rgba(2, 182, 179, 0.12)",
  gray17: "#1db9aa",
  darkGray9: "#283447",
  purple4: "#6e00ff",
  gray18: "#333",
  gray19: "#6c757d",
  yellow: "#FEEB99",
  gray20: "#555",
  darkGray10: "#1f1f1f",
  gray21: "#a0a0a0",
  translucentBlack8: "rgba(0, 0, 0, 0.125)",
  gray22: "#BABABA",
  translucentTeal2: "rgba(0, 67, 70, 0.32)",
  gray23: "#656565",
  gray24: "#616161",
  gray25: "#bdbdbd",
  gray26: "#142c44",
};
