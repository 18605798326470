import React, { useEffect, useRef, useState } from "react";
import {
  ButtonContainer2,
  Description,
  DetailsContainer,
  DetailsSummary,
  DetailsSummaryContainer,
  FilledButton,
  Prop,
  ResponsiveFlex,
  TextLabel,
  TokenBalance,
  TokenBalanceContainer,
} from "./style";
import FormCard from "../../../reusables/cards/FormCard";
import StepperComponent from "../../../reusables/Steppers";
import { useTheme } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import InputSelect from "../../../reusables/Inputs/InputSelect";
import CustomToggleSwitch from "../../../reusables/Toggle";
import MonacoEditor from "../../../reusables/MonacoEditor";
import { defineCustomTheme } from "../../../reusables/MonacoEditor/customTheme";
import transactionFee from "../../../../methods/contracts/getTransactionFee";
import { Button, Modal } from "react-bootstrap";
import fetchTokenBalancesFromCovalent, {
  validateFormInputs,
} from "../../../../methods/helper";
import { fillCreatePoolForm } from "../../../../redux/actions/poolAction";
import ErrorMessage from "../../../reusables/MessagePopups/ErrorMessage";

const FormTwo = ({
  activeStep,
  steps,
  setActiveStep,
  walletAddress,
  currentTheme,
}) => {
  const theme = useTheme();
  const poolInfo = useSelector((state) => state.PoolReducer);
  const [errors, setErrors] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const user = useSelector((state) => state.persistedStore);
  const [token, setToken] = useState("");
  const [listOfAddresses, setListOfAddresses] = useState([]);
  const [sendNativeToken, setSendNativeToken] = useState(false);
  const [totalAmout, setTotalAmount] = useState(0);
  const [transFee, setTransFee] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const amounts = listOfAddresses?.map((item) => {
    return item?.amount;
  });

  const addresses = listOfAddresses?.map((item) => {
    return item?.address;
  });

  // this should take you to the next form
  const handleNextStep = (e) => {
    e.preventDefault();
    console.log(
      !poolInfo?.recipientAmounts,
      poolInfo
    );
    if (!poolInfo?.poolToken && poolInfo?.isNativeToken === false) {
      !validateFormInputs("Select token to proceed", errors) &&
        setErrors([...errors, "Select token to proceed"]);
    } else if (
      !poolInfo?.recipientAmounts &&
      !poolInfo?.recipientAddresses?.length
    ) {
      !validateFormInputs("Recipients address and amount is missing", errors) &&
        setErrors([...errors, "Recipients address and amount is missing"]);
    } else {
      setActiveStep(2);
    }
  };

  // this should take you to the prev form
  const handlePrevStep = (e) => {
    e.preventDefault();
    setActiveStep(0);
  };

  // This should set the selected token to be native token
  const setIsNativeToken = (value) => {
    dispatch(
      fillCreatePoolForm({
        props: ["isNativeToken"],
        value: value,
      })
    );
    setSendNativeToken(value);
  };

  const setTokenValue = (value) => {
    console.log(value)
    setToken(value)
  };

  useEffect(() => {
    console.log(token);
    dispatch(
      fillCreatePoolForm({
        props: ["poolToken"],
        value: token,
      })
    );
  }, [token]);

  // This should fetch the native token balance of the connected network on page rendering and user object update
  useEffect(() => {
    if (user.address) {
      const web3 = new Web3(window.ethereum); // Replace with your Infura Project ID
      const fetchBalance = async () => {
        let balanceWei = await web3.eth.getBalance(user.address);
        // Convert balance from Wei to Ether
        const formattedBalance = web3.utils.fromWei(balanceWei, "ether");
        setWalletBalance(formattedBalance);
        dispatch(
          fillCreatePoolForm({
            props: ["walletBalance"],
            value: formattedBalance,
          })
        );
      };
      fetchBalance();
    }
  }, [user]);

  useEffect(() => {
    defineCustomTheme(theme.body);
  }, [theme, currentTheme]);

  useEffect(() => {
    const total = amounts?.reduce(
      (curr, prev) => Number(curr) + Number(prev),
      0
    );
    setTotalAmount(total);
  }, [listOfAddresses]);

  // This should update the receipientAmounts and receipientAddresses on the pool state
  useEffect(() => {
    dispatch(
      fillCreatePoolForm({
        props: ["recipientAmounts"],
        value: amounts,
      })
    );

    dispatch(
      fillCreatePoolForm({
        props: ["recipientAddresses"],
        value: addresses,
      })
    );
  }, [amounts]);

  // useEffect(() => {
  //   const fetchTransactionFee = async () => {
  //     const fee = await transactionFee(amounts, addresses, user?.network);
  //     setTransFee(fee?.totaltransactionFee);
  //   };
  //   fetchTransactionFee();
  // }, [listOfAddresses]);

  useEffect(() => {
    async function fetchTokenBalances() {
      if (user?.address) {
        const tokenListFrom = await fetchTokenBalancesFromCovalent(
          user?.address,
          user?.network?.chainId
        );
        console.log(tokenListFrom)
        setTokenList(tokenListFrom);
      }
    }
    // Call the async function
    user && fetchTokenBalances();
  }, [user]);

  return (
    <FormCard>
      <StepperComponent activeStep={activeStep} steps={steps} />
      <Description>
        <TextLabel>Payment Details</TextLabel>
        <Prop>Select your preferred payment details</Prop>
        <DetailsContainer style={{ width: "100%" }}>
          <InputSelect
            value={token}
            onChange={setTokenValue}
            // onInputChange={handleSearchChange}
            options={tokenList}
            walletAddress={user.address}
            placeholder="Paste or select token address"
            disabled={sendNativeToken}
            label={"Token address"}
          />
          <TokenBalanceContainer>
            <TokenBalance>
              {walletBalance + " " + user?.network?.nativeCurrency?.symbol}
            </TokenBalance>
            <CustomToggleSwitch
              isToggled={sendNativeToken}
              setIsToggled={setIsNativeToken}
            />
          </TokenBalanceContainer>
        </DetailsContainer>
      </Description>
      <Description>
        <ResponsiveFlex>
          <div>
            <TextLabel>
              Recipients' Addresses ({listOfAddresses?.length || 0})
            </TextLabel>
            <Prop>Add recipients’ addresses</Prop>
          </div>
          <Button
            variant="link"
            onClick={() => setShowModal(true)}
            style={{ color: theme.black }}
          >
            Show Sample CSV
          </Button>
        </ResponsiveFlex>
        <MonacoEditor
          upload={true}
          getWantedValue={setListOfAddresses}
          currentTheme={currentTheme}
        />
      </Description>

      <DetailsSummaryContainer>
        {/* <DetailsSummary>
          <span className="prop">Amount</span>
          <span className="value">
            {totalAmout?.toFixed(4)}{" "}
            {sendNativeToken
              ? user?.network?.nativeCurrency?.symbol
              : token?.symbol}
          </span>
        </DetailsSummary> */}
        {/* <DetailsSummary>
          <span className="prop">Transaction fee</span>
          <span className="value">
            {transFee?.toFixed(4)} {user?.network?.nativeCurrency?.symbol}
          </span>
        </DetailsSummary> */}
        <DetailsSummary>
          <span className="prop">Total Amount</span>
          <span className="value">
            {totalAmout?.toFixed(4)}{" "}
            {sendNativeToken
              ? user?.network?.nativeCurrency?.symbol
              : token?.symbol}
          </span>
        </DetailsSummary>
      </DetailsSummaryContainer>
      {errors?.length > 0 && (
        <ErrorMessage
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "red",
              }}
            >
              {errors?.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </div>
          }
        />
      )}

      <Description style={{ border: "none" }}>
        <ButtonContainer2>
          <FilledButton onClick={handlePrevStep}>Back</FilledButton>
          <FilledButton onClick={handleNextStep}>Continue</FilledButton>
        </ButtonContainer2>
      </Description>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <Modal.Title>Sample CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <MonacoEditor
            initialValue={
              "0xB401bE00C3F9ddce5e9Ec0F60f2fAECC005A9728,0.000056 0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,13.45 0x7D52422D3A5fE9bC92D3aE8167097eE09F1b347d,1.049 0x64c9525A3c3a65Ea88b06f184F074C2499578A7E,1"
            }
            ref={editorRef}
            download={true}
            readyOnly={true}
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <div
            className="button_container"
            style={{
              background: theme.body,
            }}
          >
            <Button
              onClick={() => setShowModal(false)}
              style={{ background: theme.greenLight, color: theme.black }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </FormCard>
  );
};

export default FormTwo;
