import React, { useState } from "react";
import FormCard from "../../../reusables/cards/FormCard";
import StepperComponent from "../../../reusables/Steppers";
import TextInputWithIcon from "../../../reusables/Inputs/TextInputWithIcon";
import SquareFilledButton from "../../../reusables/Buttons/SqaureFilledButton";
import DatePicker from "react-datepicker";

import {
  ButtonContainer,
  Container,
  GenerateButton,
  InputContainer,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import { fillCreatePoolForm } from "../../../../redux/actions/poolAction";
import ErrorMessage from "../../../reusables/MessagePopups/ErrorMessage";
import { generateRandom, validateFormInputs } from "../../../../methods/helper";
import { useTheme } from "styled-components";
import CustomDatePicker from "../../../reusables/Inputs/DatePicker";
import moment from "moment";

const FormOne = ({ activeStep, steps, setActiveStep }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const poolInfo = useSelector((state) => state.PoolReducer);
  const theme = useTheme();


  const handleAutoGenerate = () => {
    const randomNumbers = generateRandom(10);
    dispatch(
      fillCreatePoolForm({
        props: ["poolIdentifier"],
        value: randomNumbers,
      })
    );
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (!poolInfo?.poolName) {
      !validateFormInputs("Pool name cannot be empty", errors) &&
        setErrors([...errors, "Pool name cannot be empty"]);
    } else if (!poolInfo?.poolIdentifier) {
      !validateFormInputs("Pool identifier cannot be empty", errors) &&
        setErrors([...errors, "Pool identifier cannot be empty"]);
    } else if (!poolInfo?.poolDescription) {
      !validateFormInputs("Pool description cannot be empty", errors) &&
        setErrors([...errors, "Pool description cannot be empty"]);
    } else if (!poolInfo?.poolEndDate) {
      !validateFormInputs("Pool end date cannot be empty", errors) &&
        setErrors([...errors, "Pool end date cannot be empty"]);
    } else {
      setActiveStep(1);
      setErrors([]);
    }
  };


  return (
    <FormCard>
      <StepperComponent activeStep={activeStep} steps={steps} />
      <InputContainer>
        <Container>
          <TextInputWithIcon
            label="Pool Name"
            placeholder={"Enter pool name here"}
            value={poolInfo?.poolName}
            handleChange={(e) =>
              dispatch(
                fillCreatePoolForm({
                  props: ["poolName"],
                  value: e.target.value,
                })
              )
            }
          />
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextInputWithIcon
              label="Unique Identifier"
              placeholder={"Enter unique identifier here"}
              value={poolInfo?.poolIdentifier}
              handleChange={(e) =>
                dispatch(
                  fillCreatePoolForm({
                    props: ["poolIdentifier"],
                    value: e.target.value,
                  })
                )
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
              }}
            >
              <span style={{ color: theme.body }}>.</span>
              <GenerateButton onClick={handleAutoGenerate}>
                Auto Generate
              </GenerateButton>
            </div>
          </div>
          <TextInputWithIcon
            label="Description"
            placeholder={"Enter description here"}
            value={poolInfo?.poolDescription}
            handleChange={(e) => {
              dispatch(
                fillCreatePoolForm({
                  props: ["poolDescription"],
                  value: e.target.value,
                })
              );
            }}
          />
          <CustomDatePicker

            label="End Date"
            value={poolInfo?.poolEndDate}
            type={"date"}
            handleChange={(value) => {
              dispatch(
                fillCreatePoolForm({
                  props: ["poolEndDate"],
                  value: value,
                })
              );
            }}
          />
          {errors?.length > 0 && (
            <ErrorMessage
              message={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "red",
                  }}
                >
                  {errors?.map((error) => (
                    <span key={error}>{error}</span>
                  ))}
                </div>
              }
            />
          )}
          <ButtonContainer>
            <SquareFilledButton
              btnText={"Continue"}
              handleClick={handleNextStep}
            />
          </ButtonContainer>
        </Container>
      </InputContainer>
    </FormCard>
  );
};

export default FormOne;
