import _const from "../types";

const initialState = {
  pullingPool: false,
  myPool: [],
  myPoolRewards: [],
  rewardToClaim: {},
  poolToken: {},
  walletBalance: 0,
  gettingClaimedPools: false,
  claimedPools: [],
  gettingExpiredEligiblePools: false,
  expiredEligiblePools: [],
  gettingEligiblePools: false,
  eligiblePools: [],
  gettingExpiredPoolByCreator: false,
  expiredPoolsByCreator: [],
  gettingPoolSByCreator: false,
  poolsByCreator: [],

  //create pool
  poolName: "",
  poolIdentifier: "",
  poolDescription: "",
  poolEndDate: "",
  recipientAddresses: [],
  recipientAmounts: [],
  isNativeToken: false,
  createdPoolTx: null,
  claimPoolTx: null,
};

const PoolReducer = (state = initialState, action) => {
  switch (action.type) {
    // all active pools
    case _const.PULLING_POOL:
      return {
        ...state,
        pullingPool: action.payload,
      };
    case _const.SAVE_MY_POOL:
      return {
        ...state,
        myPool: action.payload,
      };

    // claimed pools
    case _const.GETTING_CLAIMED_POOLS:
      return {
        ...state,
        gettingClaimedPools: action.payload,
      };
    case _const.SAVE_CLAIMED_POOLS:
      return {
        ...state,
        claimedPools: action.payload,
      };

    // expired eligible pools
    case _const.GETTING_EXPIRED_ELIGIBLE_POOLS:
      return {
        ...state,
        gettingExpiredEligiblePools: action.payload,
      };
    case _const.SAVE_EXPIRED_ELIGIBLE_POOLS:
      return {
        ...state,
        expiredEligiblePools: action.payload,
      };

    // eligible pools
    case _const.GETTING_ELIGIBLE_POOLS:
      return {
        ...state,
        gettingEligiblePools: action.payload,
      };
    case _const.SAVE_ELIGIBLE_POOLS:
      return {
        ...state,
        eligiblePools: action.payload,
      };

    // expired pools by creator
    case _const.GETTING_EXPIRED_POOL_BY_CREATOR:
      return {
        ...state,
        gettingExpiredPoolByCreator: action.payload,
      };
    case _const.SAVE_EXPIRED_POOL_BY_CREATOR:
      return {
        ...state,
        expiredPoolsByCreator: action.payload,
      };

    // pools by creator
    case _const.GETTING_POOL_BY_CREATOR:
      return {
        ...state,
        gettingPoolSByCreator: action.payload,
      };
    case _const.SAVE_POOL_BY_CREATOR:
      return {
        ...state,
        poolsByCreator: action.payload,
      };

    // pool rewards
    case _const.SAVE_MY_POOL_REWARDS:
      return {
        ...state,
        myPoolRewards: action.payload,
      };
    case _const.FILL_CREATE_POOL_FORM:
      return {
        ...state,
        [action.payload.props]: action.payload.value,
      };

    default:
      return state;
  }
};

export default PoolReducer;
