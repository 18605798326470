import React from "react";
import styled from "styled-components";

const networks = [
  {
    name: "Asset Chain",
    light: "/assets/img/xend_finance_light.svg",
    dark: "/assets/img/xend_finance_dark.svg",
  },
  {
    name: "Binance Smartchain",
    light: "/assets/img/SVG/binance_light.svg",
    dark: "/assets/img/SVG/binance_dark.svg",
  },
  {
    name: "Celo",
    light: "/assets/img/SVG/celo_light.svg",
    dark: "/assets/img/SVG/celo_dark.svg",
  },
  {
    name: "Arbitrum",
    light: "/assets/img/SVG/arbitrum_light.svg",
    dark: "/assets/img/SVG/arbitrum_dark.svg",
  },
  {
    name: "Polygon",
    light: "/assets/img/SVG/polygon_light.svg",
    dark: "/assets/img/SVG/polygon_dark.svg",
  },
  {
    name: "Heco Chain",
    light: "/assets/img/SVG/heco_chain_light.svg",
    dark: "/assets/img/SVG/heco_chain_dark.svg",
  },
  {
    name: "Avalanche",
    light: "/assets/img/SVG/avalanche_light.svg",
    dark: "/assets/img/SVG/avalanche_dark.svg",
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  z-index: 100;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.black};
  margin-bottom: 20px;
  font-size: 36px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 56px;
  }
`;

const NetworksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1560px;
  gap: 20px;

  @media (min-width: 768px) {
    display: flex;
    gap: 40px;
  }
`;

const NetworkItem = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.gray26};
  border-radius: 10px;
  border: 1px solid #3a547a;
  transition: transform 0.2s;
  width: 100%;
  max-width: 300px;
  gap: 20px;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    max-width: 350px;
  }
`;

const NetworkIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
`;

const NetworkName = styled.span`
  color: ${({ theme }) => theme.black};
  font-size: 16px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const SupportedNetworks = ({ currentTheme }) => {
  return (
    <Container>
      <Title>Supported Networks</Title>
      <NetworksGrid>
        {networks.map((network) => (
          <NetworkItem key={network.name}>
            <NetworkIcon
              src={currentTheme === "light" ? network.light : network.dark}
              alt={`${network.name} icon`}
            />
            <NetworkName>{network.name}</NetworkName>
          </NetworkItem>
        ))}
      </NetworksGrid>
    </Container>
  );
};

export default SupportedNetworks;
