import { BlockchainSelector, NetworksContainer } from "./style";
import ConnectWalletModal from "../reusables/modal/ConnectWalletModal";
import { ChildrenContent } from "../reusables/modal/modalstyles";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../CustomLoader";
import { useEffect } from "react";
import { getNetworks } from "../../redux/actions";

const SelectNetwork = ({ connectToNetwork, currentTheme }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const supportedNetworks = useSelector(
    (state) => state.networks.supportedNetworks
  );
  const gettingNetworks = useSelector(
    (state) => state.networks.gettingNetworks
  );
  useEffect(() => {
    dispatch(getNetworks());
  }, []);

  return (
    <ConnectWalletModal>
      <img
        style={{ width: "10%" }}
        src={
          currentTheme === "light"
            ? "/assets/img/PNG/mitterpay_logo_light.png"
            : "/assets/img/PNG/mitter_logo.png"
        }
        alt="logo"
      />
      <ChildrenContent>
        <h2
          style={{ color: theme.black, fontWeight: "bold", fontSize: "30px" }}
        >
          Connect Wallet
        </h2>
        <div className="text-center">
          <p style={{ color: theme.black }}> Select the Network to connect</p>
          <NetworksContainer>
            {gettingNetworks ? (
              <CustomLoader />
            ) : (
              <>
                {supportedNetworks.map((element) => (
                  <BlockchainSelector
                    key={element.chainId}
                    onClick={() => {
                      console.log(element)
                      connectToNetwork(element);
                    }}
                  >
                    <img src={element?.image} alt={element?.chainName} />
                    <span>{element?.chainName}</span>
                  </BlockchainSelector>
                ))}
              </>
            )}
          </NetworksContainer>
        </div>
      </ChildrenContent>
    </ConnectWalletModal>
  );
};

export default SelectNetwork;
