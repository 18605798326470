import _const from "../types";
import axios from "axios";
import config from "../config";
import { authHeader } from "../api";
import { addError } from "./error";

export const fillCreatePoolForm = (payload) => {
  return (dispatch) => {
    dispatch({
      type: _const.FILL_CREATE_POOL_FORM,
      payload: payload,
    });
  };
};
//Gets payroll summary

export const getMyPoolsApi = (creatorAddress ,chainId,  page, limit) => {
  return (dispatch) => {
    dispatch({
      type: _const.PULLING_POOL,
      payload: true,
    });
    axios
      .get(config.apiUrl + `pool/by-creator`, {
        headers: authHeader(),
        params: { creatorAddress ,chainId, page, limit },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: _const.PULLING_POOL,
          payload: false,
        });
        dispatch({
          type: _const.SAVE_MY_POOL,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.PULLING_POOL,
          payload: false,
        });
        dispatch(addError(err));
      });
  };
};

export const getMyRewardPoolApi = (userAddress, chainId, page, limit) => {

  return (dispatch) => {
    dispatch({
      type: _const.GETTING_ELIGIBLE_POOLS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `pool/eligible-pools`, {
        headers: authHeader(),

        params: { userAddress, chainId, page, limit },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: _const.GETTING_ELIGIBLE_POOLS,
          payload: false,
        });
        dispatch({
          type: _const.SAVE_MY_POOL_REWARDS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_ELIGIBLE_POOLS,
          payload: false,
        });
        dispatch(addError(err));
      });
  };
};

export const saveRewardPoolTxApi = (poolInfo) => {
  console.log(poolInfo);
  axios
    .post(config.apiUrl + `pool`, poolInfo, {
      headers: authHeader(),
    })
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveRewardPoolClaimTxApi = (salt, walletAddress, txHash) => {
  axios
    .put(
      config.apiUrl + `pool/${salt}/claim`,
      { address: walletAddress, txHash: txHash },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
};



// getting expired pools by creator

export const getExpiredPoolsByCreator = (creatorAddress,chainId, page, limit) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_EXPIRED_POOL_BY_CREATOR,
      payload: true,
    });
    axios
      .get(config.apiUrl + `pool/expired-by-creator`, {
        headers: authHeader(),

        params: { creatorAddress,chainId, page, limit },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: _const.GETTING_EXPIRED_POOL_BY_CREATOR,
          payload: false,
        });
        dispatch({
          type: _const.SAVE_EXPIRED_POOL_BY_CREATOR,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_EXPIRED_POOL_BY_CREATOR,
          payload: false,
        });
        dispatch(addError(err));
      });
  };
};


// getting expired eligible pools
export const getExpiredEligiblePools = (userAddress,chainId, page, limit) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_EXPIRED_ELIGIBLE_POOLS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `pool/expired-eligible-pools`, {
        headers: authHeader(),

        params: { userAddress,chainId, page, limit },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: _const.GETTING_EXPIRED_ELIGIBLE_POOLS,
          payload: false,
        });
        dispatch({
          type: _const.SAVE_EXPIRED_ELIGIBLE_POOLS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_EXPIRED_ELIGIBLE_POOLS,
          payload: false,
        });
        dispatch(addError(err));
      });
  };
};

// getting clamed pool

export const getClaimedPoolS = (userAddress,chainId, page, limit) => {
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_CLAIMED_POOLS,
      payload: true,
    });
    axios
      .get(config.apiUrl + `pool/claimed-pools`, {
        headers: authHeader(),
        params: { userAddress, chainId, page, limit },

      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: _const.GETTING_CLAIMED_POOLS,
          payload: false,
        });
        dispatch({
          type: _const.SAVE_CLAIMED_POOLS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_CLAIMED_POOLS,
          payload: false,
        });
        dispatch(addError(err));
      });
  };
};
