import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const MastheadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 140px;
  background-color: transparent;
  color: #fff;
  z-index: 100;

  @media (max-width: 1200px) {
    padding: 0 80px;
  }

  @media (max-width: 992px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  @media (max-width: 576px) {
    padding: 0 10px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex: 0.6;
  gap: 15px;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 1200px) {
    font-size: 40px;
  }

  @media (max-width: 992px) {
    font-size: 38px;
  }

  @media (max-width: 768px) {
    font-size: 34px;
  }

  @media (max-width: 576px) {
    font-size: 28px;
  }
`;

const SubTitle = styled.p`
  font-size: 26px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.blue8};

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

const Button = styled.a`
  background-color: ${({ theme }) => theme.greenLight};
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 300px;
  border: 1px solid #38d8ab;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
  transition: background-color 0.3s, box-shadow 0.3s;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 992px) {
    width: 250px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 200px;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    width: 180px;
    padding: 10px 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }
`;

const Image = styled.img`
  width: 460px;
  flex: 0.4;

  @media (max-width: 1200px) {
    width: 500px;
  }

  @media (max-width: 992px) {
    width: 450px;
  }

  @media (max-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    max-width: 400px;
  }
`;

const AirdropMasthead = () => {

  return (
    <MastheadContainer>
      <LeftColumn>
        <Title>Airdrop Pool</Title>
        <SubTitle>🚀 Distribute & Claim Crypto Airdrops with Ease</SubTitle>
        <SubTitle>
          Whether you're a project looking to pay out winners or a user ready to
          claim your crypto earnings, our platform makes it seamless and secure.
        </SubTitle>
        <Button href="/create-pool">Create Pool</Button>
      </LeftColumn>
      <Image
        src="/assets/img/PNG/Landing_pic2.svg"
        alt="Achievements graphic"
      />
    </MastheadContainer>
  );
};

export default AirdropMasthead;
