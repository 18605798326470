/* eslint-disable react/require-default-props */
import { useRef } from "react";
import {
  //   PageCount,
  //   PaginationContainer,
  Table,
  TableBody,
  TableContainer,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  //   TableTitle,
} from "./style";
// import Loader from "../Loaders/MoonLoader";
// import NoRecordDisplay from "../Messages/NoRecordDisplay";
// // import ReactPaginate from "react-paginate";
// import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

const CustomTable = ({
  data,
  loading,
  //   pageCount,
  //   currentPage,
  tableId,
  tableRef,
  //   page,
  noRecordText,
  noRecord,
  //   ChangePage,
  //   itemCount,
  //   perPage,
  //   hasNextPage,
  //   hasPrevPage,
}) => {
  const tableScroll = useRef();
  const scrollToTop = () => {
    tableScroll.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <TableContainer ref={tableScroll}>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TableTitle>{title}</TableTitle>
        {showFilter && filter}
      </div>
      {searchOptions} */}
      {!loading ? (
        <div style={{ width: "100%", overflowX: "auto" }}>
          {!noRecord ? (
            <>
              <Table id={tableId} ref={tableRef}>
                <TableHeader>
                  <TableRow>
                    {data?.columns?.map((column, index) => (
                      <TableHead
                        key={column + index}
                        // style={{ width: column.width }}
                      >
                        {column?.label}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {data?.rows?.map((row, index) => (
                    <TableRow key={row + index}>
                      {data?.columns?.map((column, index) => (
                        <TableData
                          key={column + index}
                          // style={{ width: column.width }}
                        >
                          {row[`${column.field}`]}
                        </TableData>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              ></div>
            </>
          ) : (
            <div>No Record</div>
          )}
        </div>
      ) : (
        // <Loader />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Loader color="#B603C9" size="20px" /> */}
          "Loading ..."
        </div>
      )}
    </TableContainer>
  );
};

export default CustomTable;
