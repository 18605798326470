import React from "react";
import styled from "styled-components";

const ParentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  width: 100%; /* Ensure it takes up full width of the parent */
`;

const Label = styled.label`
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.darkGray8};
  background: #ffffff08;
  padding: 5px;

  @media (max-width: 768px) {
    height: 60px;
  }

  @media (max-width: 480px) {
    height: 50px;
  }
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.black};

  svg {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  background: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: ${({ theme }) => theme.black};

  &::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: ${({ theme }) => theme.gray25};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const TextInputWithIcon = ({
  placeholder,
  type,
  icon,
  value,
  name,
  handleChange,
  handleKeyUp,
  max,
  min,
  maxLength,
  minLength,
  readonly,
  onFocus,
  label,
}) => {
  return (
    <ParentContainer>
      <Label>{label}</Label>
      <Container>
        <Input
          placeholder={placeholder}
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          max={max}
          min={min}
          onKeyUp={handleKeyUp}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readonly}
          onFocus={onFocus}
        />
        <Icon data-testid="icon">{icon}</Icon>
      </Container>
    </ParentContainer>
  );
};

export default TextInputWithIcon;
