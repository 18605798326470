import React from "react";
import styled from "styled-components";

const HowItWorksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 140px;
  background-color: transparent;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 50px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const SubTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.lightGray};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Steps = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.greenLight};
  border-radius: 20px;
  padding: 20px;
  text-align: left;
  background: ${({ theme }) =>
    theme.currentTheme === "light" ? "#fff" : "rgba(255, 255, 255, 0.3)"};

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }

  img {
    margin-right: 10px;

    @media (max-width: 768px) {
      margin: 0 0 10px 0;
    }
  }
`;

const StepNumber = styled.h2`
  color: #779bb8;
  font-size: 50px;
  font-weight: 700;
  flex: 0.1;

  @media (max-width: 768px) {
    font-size: 36px;
    flex: 1;
  }
`;

const StepImage = styled.div`
  flex: 0.2;

  img {
    width: 92px;

    @media (max-width: 768px) {
      width: 50px;
    }
  }
`;

const StepText = styled.div`
  flex: 1;
  font-size: 24px;
  color: ${({ theme }) => theme.text};

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: left;
  }

  p {
    font-size: 18px;
    color: ${({ theme }) => theme.lightGray};

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding-left: 100px;
  padding-top: 100px;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 20px;
  }
`;

const Image = styled.img`
  width: 560px;
  height: 500px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HowItWorks = ({ currentTheme }) => {
  return (
    <HowItWorksContainer id="tutorial">
      <LeftColumn>
        <Title>Let's see how MitterPay works</Title>
        <SubTitle>Why Individuals and Businesses prefer MitterPay</SubTitle>
        <Steps>
          <StepItem
            style={{
              background:
                currentTheme === "light" ? "#fff" : "rgba(255, 255, 255, 0.3)",
            }}
          >
            <StepNumber>1</StepNumber>
            <StepImage>
              <img
                src="/assets/img/PNG/Group 3673Connect_wallet_pic.png"
                alt="Connect Wallet"
              />
            </StepImage>
            <StepText>
              Connect Wallet
              <p>
                Connect your web3 wallet from which the payment is to be made from
              </p>
            </StepText>
          </StepItem>
          <StepItem
            style={{
              background:
                currentTheme === "light" ? "#fff" : "rgba(255, 255, 255, 0.3)",
            }}
          >
            <StepNumber>2</StepNumber>
            <StepImage>
              <img
                src="/assets/img/PNG/Group 3672Add_payment_pic (1).png"
                alt="Add Payment Details"
              />
            </StepImage>
            <StepText>
              Multi-send token
              <p>Create or upload receiving payment addresses to get started and send them money with one transaction hash</p>
            </StepText>
          </StepItem>
          <StepItem
            style={{
              background:
                currentTheme === "light" ? "#fff" : "rgba(255, 255, 255, 0.3)",
            }}
          >
            <StepNumber>3</StepNumber>
            <StepImage>
              <img
                src="/assets/img/PNG/Group 3673Auth_payment_pic.png"
                alt="Authorize Payment"
              />
            </StepImage>
            <StepText>
              Airdrop Pool
              <p>
                Create an airdrop pool and upload all the receiving addresses, allowing the receiving addresses to claim thier reward
              </p>
            </StepText>
          </StepItem>
        </Steps>
      </LeftColumn>
      <ImageContainer>
        <Image src="/assets/img/PNG/how_it_works_pic.svg" alt="How it works" />
      </ImageContainer>
    </HowItWorksContainer>
  );
};

export default HowItWorks;
