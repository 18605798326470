import axios from "axios";
import { toast } from "react-toastify";
import Web3 from "web3";

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e1 = parseInt(x.toString().split("e-")[1]);
    if (e1) {
      x *= Math.pow(10, e1 - 1);
      x = "0." + new Array(e1).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

//format transaction hash
export const formathash = (hash) => {
  if (hash) {
    let firsthalf = hash.slice(0, 4);
    let secondhalf = hash.slice(-3);
    let formattedhash = firsthalf + "..." + secondhalf;
    return formattedhash;
  }
};

export const formatAddress = (address) => {
  if (!address) return "";
  const start = address.slice(0, 6);
  const end = address.slice(-4);
  return `${start}...${end}`;
};

const fetchTokenBalancesFromCovalent = async (walletAddress, chainId) => {
  const apiKey = "cqt_rQ4RWCMpYwmCgwXpTCG9gdQjyrwX"; // Replace with your Covalent API key
  const url = `https://api.covalenthq.com/v1/${chainId}/address/${walletAddress}/balances_v2/?key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.error === false) {
      console.log(data.data)
      return data.data.items.map((token) => {
        console.log()
        if (!token.native_token) {
          return {
            tokenAddress: token.contract_address,
            symbol: token.contract_ticker_symbol,
            decimal: token.contract_decimals,
            balance: (
              token.balance / Math.pow(10, token.contract_decimals)
            ).toFixed(4),
          };
        }
      });
    } else {
      throw new Error(data.error_message);
    }
  } catch (error) {
    console.error("Error fetching token balances:", error);
    return null;
  }
};

export default fetchTokenBalancesFromCovalent;

export const isValidAddress = (address) => {
  return Web3.utils.isAddress(address);
};
export const checkDuplicatesAndValidity = async (lines) => {
  let seen = {};
  let errors = [];

  for (let i = 0; i < lines?.length; i++) {
    const line = lines[i];
    let address = line?.address;

    if (!isValidAddress(line?.address)) {
      errors.push(`Line ${i + 1}: Invalid address ${address}`);
      continue; // Skip checking duplicates for invalid addresses
    }
    // Check if the amount has more than two decimal places
    if (line?.amount && !/^\d+(\.\d{1,4})?$/.test(line?.amount)) {
      errors.push(
        `Line ${i + 1}: Invalid amount ${
          line?.amount
        }. Amount must have at most four decimal places.`
      );
      break;
    }

    if (seen[address]) {
      errors.push(
        `Line ${i + 1}: Duplicate address ${address} found on Line ${
          seen[address]
        }`
      );
    } else {
      seen[address] = i + 1; // Store line number where address is seen
    }
  }

  return errors;
};

export const calculateTotalAmount = (dataArray) => {
  return dataArray.reduce((total, item) => {
    // Parse the amount as a float and add it to the total
    return total + parseFloat(item.amount);
  }, 0);
};

export const formatToFourDecimalPlaces = (num) => {
  const roundedNum = Math.round(Number(num) * 10000) / 10000; // Round to 4 decimal places
  return roundedNum.toString();
};

export const validateFormInputs = (message, errors) => {
  const res = errors?.filter((err) => err === message);
  return res?.length > 0 ? true : false;
};

export function formatTransactionHash(hash, startLength = 10, endLength = 5) {
  if (!hash || hash.length < startLength + endLength) {
    return hash; // Return the original hash if it's too short to format
  }

  const start = hash.substring(0, startLength);
  const end = hash.substring(hash.length - endLength, hash.length);

  return `${start}...${end}`;
}

export function copyToClipboard(text) {
  // Check if the browser supports the Clipboard API
  if (navigator.clipboard && window.isSecureContext) {
    // Use the Clipboard API to copy the text
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy text: ", err);
      });
  } else {
    // Fallback method for older browsers
    let textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid showing the textarea
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      toast.success("Text copied to clipboard (fallback)!");
    } catch (err) {
      toast.success("Failed to copy text: ", err);
    }

    document.body.removeChild(textArea);
  }
}

export function generateRandom(quantity) {
  let randomNumberString = "";

  for (let i = 0; i < quantity; i++) {
    const randomDigit = Math.floor(Math.random() * 10); // Generates a random digit between 0-9
    randomNumberString += randomDigit;
  }

  return randomNumberString;
}

