import React, { useEffect, useState } from "react";
import { AirdropPoolContainer, ParentContainer } from "./style";
import AirdropMasthead from "./AirdropMastHead";
// import Header from "../reusables/Navs/Header";
import MyPools from "./MyPools";
import MyRewards from "./MyRewards";
import Footer from "../reusables/Navs/Footer";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet, resetUser, toggleModal } from "../../redux/actions";
import modalConstants from "../reusables/modal/modalConstants";
import SelectNetwork from "../auth/SelectNetwork";
import { formatAddress } from "../../methods/helper";
import AuthHeader from "../reusables/Navs/AuthHeader";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../redux";

const RouteSwitchContainer = styled.div`
  display: flex;
  max-width: 300px;
  height: 44px;
  gap: 0px;
  border-radius: 37px;
  align-items: center;
  opacity: 0px;
  background: #ffffff1f;
  gap: 20px;
  margin: 0 140px;
  text-align: center;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.white};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    background-color: ${({ theme }) => theme.greenLight};
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: auto;
    height: 44px;
    border: 1px solid #38d8ab;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
    transition: background-color 0.3s, box-shadow 0.3s;

    @media (max-width: 992px) {
      width: 250px;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      width: 200px;
      font-size: 14px;
    }

    @media (max-width: 576px) {
      width: 180px;
      padding: 10px 15px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
`;

const PrivateAirdropPool = ({ currentTheme, themeToggler }) => {
  const [currentRoute, setCurrentRoute] = useState(1);
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const user = useSelector((state) => state.persistedStore);
  const [networkName, setNetworkName] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectToNetwork = async (network) => {
    dispatch(connectWallet(network));
  };

  const handleDisconnect = () => {
    persistor.purge().then(() => {
      dispatch(resetUser());
      dispatch(toggleModal(0));
      setNetworkName("");
      setWalletBalance(0);
      navigate("/");
    });
  };

  useEffect(() => {
    if (user.address) {
      const web3 = new Web3(window.ethereum); // Replace with your Infura Project ID
      const fetchBalance = async () => {
        let balanceWei = await web3.eth.getBalance(user.address);
        // Convert balance from Wei to Ether
        const formattedBalance = web3.utils.fromWei(balanceWei, "ether");
        setWalletBalance(formattedBalance);
      };
      fetchBalance();
    }
  }, [user]);

  // useEffect(() => {
  //   if (!user.isConnected) {
  //     navigate("/");
  //   }
  // }, [user, navigate]);

  useEffect(() => {
    // handle switching of account
    const handleAccountsChanged = async (accounts) => {
      const persistStore = JSON.parse(
        localStorage.getItem("persist:persistedStore")
      );

      if (accounts.length > 0) {
        const changedAddress = {
          ...persistStore,
          address: `\"${accounts[0]}\"`,
        };
        const stringifiedData = JSON.stringify(changedAddress);
        localStorage.setItem("persist:persistedStore", stringifiedData);
        window.location.reload();
      } else {
      }
    };

    // handle changing of network
    const handleChainChanged = async (chainId) => {
      // const findChangedNetwork = networks?.filter(
      //   (x) => x?.chainId === chainId
      // );
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [dispatch]);

  return (
    <ParentContainer>
      <img
        src="/assets/img/SVG/blob-1.svg"
        className="blob-1"
        alt="blob-1"
        style={{ zIndex: 1 }}
      />
      <AuthHeader
        address={formatAddress(user.address)}
        currentTheme={currentTheme}
        handleDisconnect={handleDisconnect}
        network={user?.network?.nativeCurrency?.symbol}
        themeToggler={themeToggler}
      />
      <AirdropPoolContainer>
        <AirdropMasthead />
        <MyPools />
        <MyRewards />
        <Footer />
      </AirdropPoolContainer>
      {modalPage === modalConstants.SelectNetwork ? (
        <SelectNetwork
          connectToNetwork={connectToNetwork}
          currentTheme={currentTheme}
        />
      ) : (
        ""
      )}
    </ParentContainer>
  );
};

export default PrivateAirdropPool;
