import React, { useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Header from "../../reusables/Navs/Header";
import SuccessCard from "./SuccessCard";
import FormOne from "./FormOne";
import AuthHeader from "../../reusables/Navs/AuthHeader";

export const ClaimRewardPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 100%;
  gap: 15px;

  .blob-1 {
    position: absolute;
    bottom: 0px;
    left: -150px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 100%;
  }
`;

const NavigateSection = styled.a`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  font-size: 34px;
  font-weight: 600;
  line-height: 28.8px;
  color: ${({ theme }) => theme.white};
  width: 70%;
  text-decoration: none;

  @media (max-width: 968px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 6.51px;
    border-radius: 12px;
    color: ${({ theme }) => theme.greenLight};
    font-size: 24px;
    background: #ffffff1f;
  }
`;

const ClaimReward = () => {
  const [currentForm, setCurrentForm] = useState(1);

  return (
    <>
      <AuthHeader hideNavBar={true} />
      <ClaimRewardPageContentWrapper>
        <img
          src="/assets/img/SVG/blob-2.svg"
          className="blob-1"
          alt="blob-1"
          style={{ zIndex: 1 }}
        />
        <div
          style={{
            zIndex: "100",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <NavigateSection href="/airdrop-pool/private">
            <MdKeyboardArrowLeft />
            <span>Claim Reward</span>
          </NavigateSection>
          {currentForm === 1 && <FormOne setCurrentForm={setCurrentForm} />}
          {currentForm === 2 && <SuccessCard />}
        </div>
      </ClaimRewardPageContentWrapper>
    </>
  );
};

export default ClaimReward;
