import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fillCreatePoolForm,
  getExpiredEligiblePools,
} from "../../../redux/actions/poolAction";
import { myEndedRewardsCols, myEndedRewardsRows } from "../tableUtil";
import CustomTable from "../../reusables/Table";
import Pagination from "../../reusables/Table/CustomPagination";

const EndedRewardsTable = () => {
  const user = useSelector((state) => state.persistedStore);
  const poolStore = useSelector((state) => state.PoolReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  // handle page number select
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setCurrentPage(1);
  };

  const fetchMyRewards = async () => {

    dispatch(getExpiredEligiblePools(user.address, user?.networkId, currentPage, pageLimit));
  };

  const claimReward = (pool) => {
    dispatch(
      fillCreatePoolForm({
        props: ["rewardToClaim"],
        value: pool,
      })
    );
    navigate("/claim-reward");
  };

  useEffect(() => {
    fetchMyRewards();
  }, [user.address, currentPage, pageLimit]);

  useEffect(() => {
    const processPools = async () => {
      if (poolStore.expiredEligiblePools) {
        const rows = await myEndedRewardsRows(
          poolStore.expiredEligiblePools.items,
          claimReward,
          user.network
        );
        setTableRow(rows);
      }
    };

    processPools();
  }, [poolStore.expiredEligiblePools, user?.network]);

  return (
    <>
      {!poolStore.gettingExpiredEligiblePools ? (
        // Render table here when data is available
        <>
          <CustomTable
            loading={false}
            data={{ columns: myEndedRewardsCols, rows: tableRow }}
          />
          <Pagination
            totalItems={poolStore?.expiredEligiblePools?.meta?.totalItems}
            initialItemsPerPage={pageLimit} // Default items per page
            onPageChange={handlePageChange}
            handleItemsPerPageChange={handleLimitChange}
          />
        </>
      ) : (
        // <div>Table placeholder</div>
        <div>Loading...</div>
      )}
    </>
  );
};

export default EndedRewardsTable;
