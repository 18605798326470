import React from "react";
import {
  Container,
  FilledButton2,
  InputContainer,
  RewardDetail,
  RewardDetails,
  RewardDetailsSection,
} from "../CreatePool/Sections/style";
import FormCard from "../../reusables/cards/FormCard";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getTransactionFee,
  claimReward,
  claimNativeReward,
} from "../../../methods/contracts/rewardPool";
import { getAccountInfo } from "../../../methods/contracts/getBalance";
import {
  saveRewardPoolClaimTxApi,
  fillCreatePoolForm,
} from "../../../redux/actions/poolAction";
import ErrorMessage from "../../reusables/MessagePopups/ErrorMessage";

import Web3 from "web3";

const FormOne = ({ setCurrentForm }) => {

  
    const poolStore = useSelector((state) => state.PoolReducer);
  const user = useSelector((state) => state.persistedStore);
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const [pool, setPool] = useState();
  const [transFee, setTransFee] = useState();
    const [errors, setErrors] = useState();


  const fetchTransactionFee = async (poolAddress, creatorAddress) => {
    const web3 = new Web3(window.ethereum);
    const fee = await getTransactionFee(poolAddress, creatorAddress);
    console.log(fee);
    setTransFee(web3?.utils?.fromWei(fee, "ether"));
  };

  const getTokenInfo = async (tokenContract, useraddress) => {
    let accountDetail = await getAccountInfo(tokenContract, useraddress);
    setToken(accountDetail);
  };

  const handleClaimReward = async (e) => {
    try {
      const web3 = new Web3(window.ethereum);
      e.preventDefault();
      
      let tx;
      console.log(pool?.pool)
      // Ensure both functions are properly awaited
      if (pool?.pool?.tokenContract) {
        tx = await claimReward(
          user?.address,
          pool?.pool?.poolAddress,
          pool?.pool?.tokenContract,
          web3.utils.toWei(transFee, "ether")
        );
      } else {
        tx = await claimNativeReward(user?.address, pool?.pool?.poolAddress);
      }
  
      // If transaction is successful, log and handle it
      console.log(tx);
      
      // Save the transaction via API
      await saveRewardPoolClaimTxApi(
        pool?.pool?.salt,
        user?.address,
        tx.transactionHash
      );
  
      // Dispatch form state update
      dispatch(
        fillCreatePoolForm({
          props: ["claimPoolTx"],
          value: tx,
        })
      );
  
      // Set the form to the next step
      setCurrentForm(2);
  
    } catch (err) {
      if (err?.message.includes("insufficient funds for gas")){
        setErrors(["insufficient funds for gas"]);
      }
      else if (err?.message.includes("Reward amount is less than the transaction fee")){
        setErrors(["Reward amount is less than the transaction fee"])
      }
      else if (err?.message.includes("Pool expired")){
        setErrors(["Pool expired"])
      }
      else if (err?.message.includes("Reward already claimed")){
        setErrors(["Reward already claimed"])
      }
      else if (err?.message.includes("transfer amount exceeds balance")){
        setErrors(["You do not have enough fun for gas"])
      }
      // Catch any error, including ones from child wallet interactions
      // Set the error message to state
      console.error("Error while claiming reward:", err); // Log the error for debugging
    }
  };
  

  useEffect(() => {
    if (poolStore?.rewardToClaim && user) {
      console.log(poolStore?.rewardToClaim);
      setPool(poolStore?.rewardToClaim);
      fetchTransactionFee(
        poolStore?.rewardToClaim?.pool?.poolAddress,
        user?.address
      );
      if (poolStore?.rewardToClaim?.pool?.tokenContract) {
        getTokenInfo(
          poolStore?.rewardToClaim?.pool?.tokenContract,
          user?.address
        );
      }
    }
  }, [poolStore?.rewardToClaim]);

  return (
    <FormCard>
      <InputContainer>
        <RewardDetailsSection>
          <RewardDetails>
            <RewardDetail>
              <div className="prop">Pool name:</div>
              <div className="value">{pool?.pool?.poolName}</div>
            </RewardDetail>
            <RewardDetail>
              <div className="prop">Amount Claimable:</div>
              <div className="value">
                {pool?.rewardAmount
                  ? Number(pool?.rewardAmount).toFixed(5)
                  : "0.00000"}{" "}
                {token ? token?.symbol : user?.network?.nativeCurrency?.symbol}
              </div>
            </RewardDetail>
            <RewardDetail>
              <div className="prop">Transaction Fee:</div>
              <div className="value">
                {transFee} {user?.network?.nativeCurrency?.symbol}
              </div>
            </RewardDetail>
          </RewardDetails>
          <FilledButton2 onClick={handleClaimReward}>Claim</FilledButton2>
        </RewardDetailsSection>
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "red",
                }}
              >
                {errors?.map((error) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
      </InputContainer>
    </FormCard>
  );
};

export default FormOne;
