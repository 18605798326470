import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpiredPoolsByCreator } from "../../../redux/actions/poolAction";
import { myPoolsCols, myPoolsTableRows } from "../tableUtil";
import CustomTable from "../../reusables/Table";
import Pagination from "../../reusables/Table/CustomPagination";

const ExpiredPoolsTable = () => {
  const [tableRow, setTableRow] = useState([]);
  const user = useSelector((state) => state.persistedStore); // Ensure user exists
  const poolStore = useSelector((state) => state.PoolReducer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  // handle page number select
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setCurrentPage(1);
  };

  // Fetch pools
  const fetchMyPools = async () => {

    dispatch(getExpiredPoolsByCreator(user.address,user?.networkId, currentPage, pageLimit));
  };

  useEffect(() => {
    fetchMyPools();
  }, [user.address, currentPage, pageLimit]);

  useEffect(() => {
    const processPools = async () => {
      if (poolStore.expiredPoolsByCreator) {
        const rows = await myPoolsTableRows(
          poolStore.expiredPoolsByCreator.items,
          user?.network
        );
        setTableRow(rows);
      }
    };

    processPools();
  }, [poolStore.expiredPoolsByCreator, user?.network]);

  return (
    <>
      {!poolStore.gettingExpiredPoolByCreator ? (
        // Render table here when data is available
        <>
          <CustomTable
            loading={false}
            data={{ columns: myPoolsCols, rows: tableRow }}
          />
          <Pagination
            totalItems={poolStore?.expiredPoolsByCreator?.meta?.totalItems}
            initialItemsPerPage={pageLimit} // Default items per page
            onPageChange={handlePageChange}
            handleItemsPerPageChange={handleLimitChange}
          />
        </>
      ) : (
        // <div>Table placeholder</div>
        <div>Loading...</div>
      )}
    </>
  );
};

export default ExpiredPoolsTable;
