import createContract from "./createContract";
import erc20Abi from "../erc20.json";
import { toFixed } from "../helper";
import swal from "sweetalert";


async function approveMint(amounts, currencyId, walletAddress, decimals, network) {
  try {
    //Get the total amount for all the recipients
    let approvedAmount = 0;

    for (let i = 0; i < amounts.length; i++) {
      approvedAmount = approvedAmount += amounts[i];
    }

    //Generate contract for approving mint
    const contract = await createContract(erc20Abi, currencyId);
    

    //Get the total amount of the token that the user has currently
    let balance = await contract.methods.balanceOf(walletAddress).call();
    var num = Math.pow(10, Number(decimals));
    

    let tokenBalance = Number(balance) / num;
    let total = approvedAmount ;
    
    if (tokenBalance > total || tokenBalance === total) {
      let amttoapprove = num * total;
      let value = toFixed(amttoapprove);
      console.log(network.FORWARDER_ADDRESS, value.toString(),walletAddress)
      
      let result = await contract.methods
        .approve(network.FORWARDER_ADDRESS, value.toString())
        .send({ from: walletAddress });
      return result;
    } else {
      swal(
        "Criteria Failure",
        `An attempt to approve an amount greater than your wallet balance cannot be processed`,
        "warning"
      );
      return;
    }
  } catch (error) {
    return error;
  }
}

export default approveMint;
