/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";
import FormCard from "../../../reusables/cards/FormCard";
import InputSelect from "../../../reusables/Inputs/InputSelect";
import MonacoEditor from "../../../reusables/MonacoEditor";
import { useDispatch } from "react-redux";
import fetchTokenBalancesFromCovalent, {
  checkDuplicatesAndValidity,
  formatToFourDecimalPlaces,
} from "../../../../methods/helper";
import ErrorMessage from "../../../reusables/MessagePopups/ErrorMessage";
import { saveTransactionDetails } from "../../../../redux/actions/multisendAction";
import ToggleButton from "../../../reusables/Toggle";
import { useTheme } from "styled-components";
import { defineCustomTheme } from "../../../reusables/MonacoEditor/customTheme";

const ProvideForm = ({
  handleNextStep,
  walletBalance,
  walletAddress,
  chainId,
  setSendNativeToken,
  sendNativeToken,
  nativeTokenSymbol,
  currentTheme,
}) => {
  // const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState("");
  const [listOfAddresses, setListOfAddresses] = useState("");
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [tokenList, setTokenList] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  const [errors, setErrors] = useState();
  const theme = useTheme();

  const handleContinue = async () => {
    setErrors([]);
    if (listOfAddresses?.length <= 1) {
      setErrors(["You must enter more than one address"]);
    } else {
      const errors = await checkDuplicatesAndValidity(listOfAddresses);
      if (errors?.length <= 0) {
        const multiSendDetails = {
          sender: walletAddress,
          token: token,
          receiver: listOfAddresses,
          walletBalance: {
            nativeTOkenName: nativeTokenSymbol,
            nativeTokenBalance: walletBalance,
          },
        };

        dispatch(saveTransactionDetails(multiSendDetails));
        handleNextStep();
      } else {
        setErrors(errors);
      }
    }
  };

  useEffect(() => {
    async function fetchTokenBalances() {
      if (walletAddress) {
        const tokenListFrom = await fetchTokenBalancesFromCovalent(
          walletAddress,
          chainId
        );
        setTokenList(tokenListFrom);
      }
    }
    // Call the async function
    walletAddress && chainId && fetchTokenBalances();
  }, [walletAddress, chainId]);

  useEffect(() => {
    defineCustomTheme(theme.body);
  }, [theme, currentTheme]);


  return (
    <FormCard>
      <div className="token_balance_container">
        <span>Token Address</span>
        <span>
          {formatToFourDecimalPlaces(walletBalance)}{" "}
          <span style={{ textTransform: "uppercase" }}>
            {nativeTokenSymbol}
          </span>
        </span>
      </div>
      <div className="token_balance_container">
        <InputSelect
          value={token}
          onChange={setToken}
          // onInputChange={handleSearchChange}
          options={tokenList}
          walletAddress={walletAddress}
          placeholder="Paste or Select token address"
          disabled={sendNativeToken}
        />
        <ToggleButton
          isToggled={sendNativeToken}
          setIsToggled={setSendNativeToken}
        />
      </div>
      <div className="token_balance_container">
        <span>List of addresses in CSV</span>
        <Button
          variant="link"
          onClick={() => setShowModal(true)}
          style={{ color: theme.black }}
        >
          Show Sample CSV
        </Button>
      </div>
      <MonacoEditor
        upload={true}
        getWantedValue={setListOfAddresses}
        currentTheme={currentTheme}
      />
      {errors?.length > 0 && (
        <ErrorMessage
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "red",
              }}
            >
              {errors?.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </div>
          }
        />
      )}
      <button
        // variant="primary"
        type="submit"
        className="mt-3 button"
        onClick={handleContinue}
        disabled={
          (!sendNativeToken && !token) ||
          !listOfAddresses?.length > 0 ||
          !walletBalance ||
          !walletAddress
        }
        style={{ background: theme.greenLight, color: theme.black }}
      >
        Continue
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <Modal.Title>Sample CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <MonacoEditor
            initialValue={
              "0xB401bE00C3F9ddce5e9Ec0F60f2fAECC005A9728,0.000056 0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,13.45 0x7D52422D3A5fE9bC92D3aE8167097eE09F1b347d,1.049 0x64c9525A3c3a65Ea88b06f184F074C2499578A7E,1"
            }
            ref={editorRef}
            download={true}
            readyOnly={true}
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            background: theme.body,
            border: `1px solid ${theme.greenLight}`,
          }}
        >
          <div
            className="button_container"
            style={{
              background: theme.body,
            }}
          >
            <Button
              onClick={() => setShowModal(false)}
              style={{ background: theme.greenLight, color: theme.black }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </FormCard>
  );
};

export default ProvideForm;
