
let settings = {
  apiUrl: process.env.REACT_APP_API_URL || "https://api.mitterpay.com/", // Default URL if not set
  appName: "Mitterpay",
  subject: "Mitterpay",
  baseUrl: "",
  isTestNet: false
};

export default settings;
