import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import error from "./error";
import PayrollReducer from "./payrollReducer";
import GeneralReducer from "./generalReducer";
import LoaderReducer from "./loaderReducer";
import auth from "./auth";
import ReportReducer from "./reportReducer";
import CurrencyReducer from "./currencyReducer";
import QuickSendReducer from "./quicksendReducer";
import multisendReducer from "./multisendReducer";
import networks from "./networks";
import PoolReducer from "./poolReducer";

const customSerializer = {
  stringify: (inboundState) => {
    return JSON.stringify(inboundState, (key, value) =>
      typeof value === "bigint" ? value.toString() + "n" : value
    );
  },
  parse: (outboundState) => {
    return JSON.parse(outboundState, (key, value) =>
      typeof value === "string" && /^\d+n$/.test(value)
        ? BigInt(value.slice(0, -1))
        : value
    );
  },
};

const persistConfig = {
  key: "persistedStore",
  storage,
  serialize: customSerializer.stringify,
  deserialize: customSerializer.parse,
};

export default combineReducers({
  persistedStore: persistReducer(persistConfig, auth),
  networks,
  error,
  PayrollReducer,
  GeneralReducer,
  LoaderReducer,
  ReportReducer,
  CurrencyReducer,
  QuickSendReducer,
  multisendReducer,
  PoolReducer
});
