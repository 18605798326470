/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal, resetUser, connectWallet } from "../../redux/actions";
import { MultisendPageContentWrapper } from "./styles";
import StepperComponent from "../reusables/Steppers";
import ProvideForm from "./MultisendForms/FormOne";
import ApproveForm from "./MultisendForms/FormTwo";
import { formatAddress } from "../../methods/helper";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { persistor } from "./../../redux/index";
import SuccessPage from "./MultisendForms/SuccessPage";
import modalConstants from "../reusables/modal/modalConstants";
import SelectNetwork from "../auth/SelectNetwork";
import AuthHeader from "../reusables/Navs/AuthHeader";

const steps = [
  { label: "Provide" },
  { label: "Approve" },
  { label: "Sending" },
];

const Multisend = ({ currentTheme, themeToggler }) => {
  const modalPage = useSelector((state) => state.GeneralReducer.modalPage);
  const user = useSelector((state) => state.persistedStore);

  const [activeStep, setActiveStep] = useState(0);
  const [networkName, setNetworkName] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [sendNativeToken, setSendNativeToken] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectToNetwork = async (network) => {
    dispatch(connectWallet(network));
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleDisconnect = () => {
    persistor.purge().then(() => {
      dispatch(resetUser());
      dispatch(toggleModal(0));
      setNetworkName("");
      setWalletBalance(0);
      navigate("/");
    });
  };

  useEffect(() => {
    if (user.address) {
      const web3 = new Web3(window.ethereum); // Replace with your Infura Project ID
      const fetchBalance = async () => {
        let balanceWei = await web3.eth.getBalance(user.address);
        // Convert balance from Wei to Ether
        const formattedBalance = web3.utils.fromWei(balanceWei, "ether");
        setWalletBalance(formattedBalance);
      };
      fetchBalance();
    }
  }, [user]);

  useEffect(() => {
    // handle switching of account
    const handleAccountsChanged = async (accounts) => {
      const persistStore = JSON.parse(
        localStorage.getItem("persist:persistedStore")
      );

      if (accounts.length > 0) {
        const changedAddress = {
          ...persistStore,
          // eslint-disable-next-line no-useless-escape
          address: `\"${accounts[0]}\"`,
        };
        const stringifiedData = JSON.stringify(changedAddress);
        localStorage.setItem("persist:persistedStore", stringifiedData);
        window.location.reload();
      } else {
        /* empty */
      }
    };

    // handle changing of network
    // eslint-disable-next-line no-unused-vars
    const handleChainChanged = async (chainId) => {
      // const findChangedNetwork = networks?.filter(
      //   (x) => x?.chainId === chainId
      // );
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [dispatch]);

  return (
    <div>
      {/* <Navbar collapseOnSelect expand="lg" className="top_nav">
        <Container>
          <NavItems>
            {networkName && (
              <NavItem
                className="nav-item mx-2 navbar-btn p-0 nav-link"
                style={{ textTransform: "uppercase" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(toggleModal(101));
                }}
              >
                {networkName}
              </NavItem>
            )}
          </NavItems>
        </Container>
      </Navbar> */}
      <AuthHeader
        address={formatAddress(user.address)}
        currentTheme={currentTheme}
        handleDisconnect={handleDisconnect}
        network={user?.network?.nativeCurrency?.symbol}
        themeToggler={themeToggler}
      />
      <MultisendPageContentWrapper>
        <img
          src="/assets/img/SVG/blob-2.svg"
          className="blob-1"
          alt="blob-1"
          style={{ zIndex: 1 }}
        />
        <StepperComponent activeStep={activeStep} steps={steps} />
        {activeStep === 0 && (
          <ProvideForm
            handleNextStep={handleNextStep}
            walletBalance={walletBalance}
            // connectedNetwork={networkName}
            walletAddress={user?.address}
            chainId={user?.network?.chainId}
            setSendNativeToken={setSendNativeToken}
            sendNativeToken={sendNativeToken}
            nativeTokenSymbol={user?.network?.nativeCurrency?.symbol}
            currentTheme={currentTheme}
          />
        )}
        {activeStep === 1 && (
          <ApproveForm
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            walletBalance={walletBalance}
            chainId={user?.network?.chainId}
            sendNativeToken={sendNativeToken}
            setSendNativeToken={setSendNativeToken}
            network={user?.network}
          />
        )}
        {activeStep === 2 && <SuccessPage setActiveStep={setActiveStep} />}
      </MultisendPageContentWrapper>
      {modalPage === modalConstants.SelectNetwork ? (
        <SelectNetwork
          connectToNetwork={connectToNetwork}
          currentTheme={currentTheme}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Multisend;
