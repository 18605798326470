/* eslint-disable react/prop-types */
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Home, Landing } from "./components";
import PaymentReport from "./components/paymentreport";
import PayRoll from "./components/payroll";
import PaySlip from "./components/payslip";
import { useSelector } from "react-redux";
import QuickSendRecipients from "./components/quickSendReport/QuickSendReceipients";
import QuickSendTransactions from "./components/quickSendReport/QuickSendTransactions";
import { Login } from "./components/auth/Login";
import { Dashboard } from "./components/home/dashboard";
import { NewPayroll } from "./components/home/NewPayroll";
import AllRecipients from "./components/payroll/allRecipients";
import Multisend from "./components/LandingPage/Multisend";
import Tutorial from "./components/Tutorial";
import FAQs from "./components/FAQs";
import About from "./components/About";
import CreatePool from "./components/AirdropPool/CreatePool";
import ClaimReward from "./components/AirdropPool/ClaimReward";
import PrivateAirdropPool from "./components/AirdropPool/PrivateAirdropPool";

// eslint-disable-next-line react/prop-types
const Router = ({ theme, themeToggler }) => {
  const auth = useSelector((state) => state.persistedStore);
  const ProtectedRoute = ({ children, ...rest }) => {
    return auth.isConnected ? (
      children
    ) : (
      <Navigate to="/" replace state={{ from: rest.location }} />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Landing currentTheme={theme} themeToggler={themeToggler} />}
        />
        <Route
          path="/multisend"
          element={
            <ProtectedRoute>
              <Multisend currentTheme={theme} themeToggler={themeToggler} />
            </ProtectedRoute>
          }
        />
        <Route path="/signin" element={<Login />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route
          path="/about"
          element={<About currentTheme={theme} themeToggler={themeToggler} />}
        />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/payroll" element={<NewPayroll />} />
        <Route path="/payroll/receipients" element={<AllRecipients />} />
        {/* <Route
          path="/airdrop-pool"
          element={
            <AirdropPool currentTheme={theme} themeToggler={themeToggler} />
          }
        /> */}
        <Route
          path="/airdrop-pool/private"
          element={
            <ProtectedRoute>
              <PrivateAirdropPool
                currentTheme={theme}
                themeToggler={themeToggler}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-pool"
          element={
            <ProtectedRoute>
              <CreatePool currentTheme={theme} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/claim-reward"
          element={
            <ProtectedRoute>
              <ClaimReward />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payroll/:payrollId"
          element={
            <ProtectedRoute>
              <PayRoll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report/:recepientId"
          element={
            <ProtectedRoute>
              <PaymentReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quicksend/:quicksendId"
          element={
            <ProtectedRoute>
              <QuickSendRecipients />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quicksend/transactions/:quicksendId"
          element={
            <ProtectedRoute>
              <QuickSendTransactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payslip/:transactionId"
          element={
            <ProtectedRoute>
              <PaySlip />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
