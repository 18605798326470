import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ActiveRewardsTable from "./tables/ActiveRewardsTable";
import ClaimedRewardsTable from "./tables/ClaimedRewardTable";
import EndedRewardsTable from "./tables/EndedRewardTable";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 0 140px;
  z-index: 100;

  @media (max-width: 1200px) {
    padding: 0 80px;
  }

  @media (max-width: 992px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  @media (max-width: 576px) {
    padding: 0 10px;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.black};

  @media (max-width: 1200px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const TableButtonContainer = styled.div`
  display: flex;
  width: auto;
  height: 44px;
  gap: 0px;
  border-radius: 37px;
  align-items: center;
  opacity: 0px;
  background: #ffffff1f;
  gap: 20px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.white};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    /* text-align: center; */
    padding: 10px;
    min-width: 100px;
    width: auto;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 5px;
    }
  }

  .active {
    background-color: ${({ theme }) => theme.greenLight};
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    height: 30px;
    border: 1px solid #38d8ab;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 16.4869px 25.3191px rgba(0, 0, 0, 0.06);
    transition: background-color 0.3s, box-shadow 0.3s;

    @media (max-width: 992px) {
      font-size: 12px;
      padding: 5;
    }

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 5;
    }

    @media (max-width: 576px) {
      font-size: 12px;
      padding: 5;
    }

    &:hover {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
`;

const MyRewards = () => {
  const [currentTable, setCurrentTable] = useState(1);

  return (
    <Container>
      <Title>My Rewards</Title>
      <TableButtonContainer>
        <button
          className={currentTable === 1 && "active"}
          onClick={() => setCurrentTable(1)}
        >
          Active Pools
        </button>
        <button
          className={currentTable === 2 && "active"}
          onClick={() => setCurrentTable(2)}
        >
          Ended
        </button>
        <button
          className={currentTable === 3 && "active"}
          onClick={() => setCurrentTable(3)}
        >
          Claimed
        </button>
      </TableButtonContainer>
      {currentTable === 1 && <ActiveRewardsTable />}
      {currentTable === 2 && <EndedRewardsTable />}
      {currentTable === 3 && <ClaimedRewardsTable />}
    </Container>
  );
};

export default MyRewards;
