/* eslint-disable no-undef */
const chainNames = process.env?.REACT_APP_CHAIN_NAMES?.split(",");

export const networks = chainNames?.map((chainName) => {
  const prefix = `REACT_APP_CHAIN_${chainName.toUpperCase()}`;
  const chainId = parseInt(process.env[`${prefix}_ID`]);
  return {
    chainId: Number(chainId),
    chainIdString: `0x${chainId.toString(16)}`,
    chainName: [process.env[`${prefix}_NAME`]][0],
    nativeCurrency: {
      name: `${chainName} Chain Native Token`,
      symbol: [process.env[`${prefix}_SYMBOL`]][0], // Customize as needed
      decimals: Number(18),
    },
    image: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <img
        // eslint-disable-next-line no-undef
        src={process.env[`${prefix}_IMAGE_URL`]}
        alt={`${chainName} logo`}
        style={{ height: "24px" }}
      />
    ),
    active: true,
    rpcUrls: [process.env[`${prefix}_RPC_URL`]],
    blockExplorerUrls: [process.env[`${prefix}_EXPLORER_URL`]],
    FORWARDER_ADDRESS: process.env[`${prefix}_FORWARDER_ADDRESS`],
    FORWARDER_PROXY_ADDRESS: process.env[`${prefix}_FORWARDER_PROXY_ADDRESS`],
    REWARDPOOL_FACTORY_PROXY_ADDRESS: process.env[`${prefix}_REWARD_POOL_FACTORY_PROXY_ADDRESS`],  };
});

export const nativeTokenContracts = {
  56: {
    address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52", // BNB contract address
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ], // Contract ABI for BNB token
  },
  137: {
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0", // MATIC contract address
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ], // Contract ABI for MATIC token
  },
  42161: {
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9", // MATIC contract address
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ], // Contract ABI for MATIC token
  },
};
