import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  DetailContainer,
  DetailHeaderText,
  DetailLabel,
  DetailText,
  FilledButton2,
  InputContainer,
  TextLabel,
  TransactionHash,
  TransactionHashContainer,
  TransactionHashSection,
} from "../CreatePool/Sections/style";
import FormCard from "../../reusables/cards/FormCard";
import { RiShareBoxLine } from "react-icons/ri";
import styled from "styled-components";
import {
  copyToClipboard,
  formatTransactionHash,
} from "../../../methods/helper";
import { FiCopy } from "react-icons/fi";
import web3 from "../../../methods/web3setup";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const SuccessCard = () => {
  const poolInfo = useSelector((state) => state.PoolReducer);
  const [claimPoolTx, setClaimPoolTx] = useState();
  const user = useSelector((state) => state.persistedStore);

  const formattedBlockNumber =
    claimPoolTx && claimPoolTx?.blockNumber?.toString();
  const formattedGasUsed =
    claimPoolTx &&
    web3?.utils?.fromWei(claimPoolTx?.effectiveGasPrice.toString(), "ether");

  useEffect(() => {
    if (poolInfo.claimPoolTx) {
      console.log(poolInfo.claimPoolTx);
      setClaimPoolTx(poolInfo.claimPoolTx);
    }
  }, [poolInfo]);
  return (
    <FormCard>
      <Container>
        <img
          src="/assets/img/SVG/green-check.svg"
          alt="green-check"
          style={{ width: "150px" }}
        />
        <TextLabel>Token Claimed Successfully!</TextLabel>
        <DetailHeaderText>Transaction Details</DetailHeaderText>
        <DetailContainer>
          <DetailLabel>Hash:</DetailLabel>
          <DetailText>
            <TransactionHash
              href={`${user.network.blockExplorerUrls}${claimPoolTx?.transactionHash}`}
              target="_blank"
            >
              {formatTransactionHash(claimPoolTx?.transactionHash)}
            </TransactionHash>
          </DetailText>
        </DetailContainer>
        <DetailContainer>
          <DetailLabel>Block Number:</DetailLabel>
          <DetailText>{formattedBlockNumber}</DetailText>
        </DetailContainer>
        <DetailContainer>
          <DetailLabel>Gas Used:</DetailLabel>
          <DetailText>{formattedGasUsed}</DetailText>
        </DetailContainer>
        <TransactionHashSection></TransactionHashSection>
        <TransactionHashSection>
          {/* <TextLabel>Transaction Hash:</TextLabel>
          <TransactionHashContainer>
            <span>{formatTransactionHash(claimPoolTx?.transactionHash)}</span>
            <FiCopy
              cursor={"pointer"}
              onClick={() => {
                copyToClipboard(claimPoolTx?.transactionHash);
              }}
            />
          </TransactionHashContainer> */}
          <FilledButton2 href="/airdrop-pool/private">Go to pool</FilledButton2>
        </TransactionHashSection>
      </Container>
    </FormCard>
  );
};

export default SuccessCard;
