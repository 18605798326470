/* eslint-disable react/react-in-jsx-scope */
import { FiMoreVertical } from "react-icons/fi";
import FilledButton from "../reusables/Buttons/FilledButton";
import BigNumber from 'bignumber.js';
import {getAccountInfo} from '../../methods/contracts/getBalance'
import {
  copyToClipboard,
  formatTransactionHash,
} from "../../methods/helper";
import { FiCopy } from "react-icons/fi";


export const myPoolsCols = [
  { label: "Pool Name", field: "pool_name", sort: "asc" },
  { label: "Unique Identifier", field: "unique_identifier", sort: "asc" },
  { label: "Token To Share", field: "token_share", sort: "asc" },
  { label: "Total Addresses", field: "total_addresses", sort: "asc" },
  { label: "End Date", field: "end_date", sort: "asc" },
  { label: "", field: "more_vert", sort: "asc" },
];

export const myRewardsCols = [
  { label: "Pool Name", field: "pool_name", sort: "asc" },
  { label: "Unique Identifier", field: "unique_identifier", sort: "asc" },
    { label: "Token share", field: "token_share", sort: "asc" },
  // { label: "Total Addresses", field: "total_addresses", sort: "asc" },
  { label: "End Date", field: "end_date", sort: "asc" },
  { label: "", field: "claim_button", sort: "asc" },
];

export const myEndedRewardsCols = [
  { label: "Pool Name", field: "pool_name", sort: "asc" },
  { label: "Unique Identifier", field: "unique_identifier", sort: "asc" },
    { label: "Token share", field: "token_share", sort: "asc" },
  // { label: "Total Addresses", field: "total_addresses", sort: "asc" },
  { label: "End Date", field: "end_date", sort: "asc" },
];

export const myClaimedRewardsCols = [
  { label: "Pool Name", field: "pool_name", sort: "asc" },
  { label: "Unique Identifier", field: "unique_identifier", sort: "asc" },
    { label: "Token share", field: "token_share", sort: "asc" },
  // { label: "Total Addresses", field: "total_addresses", sort: "asc" },
  { label: "End Date", field: "end_date", sort: "asc" },
  { label: "Transaction Hash", field: "tx_hash", sort: "asc" },
];

export const myPoolsTableRows = async (pools, network) => {
  if (pools && pools.length > 0) {
    const rows = [];

    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];

      // Calculate totalRewardAmount using a for loop
      let totalRewardAmount = new BigNumber(0);
      for (let j = 0; j < pool.eligibleAddresses.length; j++) {
        const address = pool.eligibleAddresses[j];
        totalRewardAmount = totalRewardAmount.plus(new BigNumber(address.rewardAmount));
      }

      const expirationTime = pool.expirationTime;
      const adjustedExpirationTime = expirationTime > 1e12 ? expirationTime / 1000 : expirationTime;

      // Convert to a human-readable date
      const expirationDate = new Date(adjustedExpirationTime * 1000).toLocaleDateString();

      // Handle async tokenContract check with promises
      if (pool.tokenContract) {
        const {name, symbol, decimals, walletBalance} = await getAccountInfo(pool.tokenContract, pool.eligibleAddresses[0].address)
   
      rows.push({
        pool_name: <span>{pool.poolName}</span>,
        unique_identifier: <span>{pool.salt}</span>,
        token_share: (
          <span>
            {totalRewardAmount.toString()}{" "}
            {pool.tokenContract ? symbol : network?.nativeCurrency?.symbol}
          </span>
        ),
        total_addresses: <span>{pool.eligibleAddresses.length}</span>,
        end_date: <span>{expirationDate}</span>,
        more_vert: <FiMoreVertical />,
      });
  
      } else {
        rows.push({
          pool_name: <span>{pool.poolName}</span>,
          unique_identifier: <span>{pool.salt}</span>,
          token_share: (
            <span>
              {totalRewardAmount.toString()}{" "}
              {network?.nativeCurrency?.symbol}
            </span>
          ),
          total_addresses: <span>{pool.eligibleAddresses.length}</span>,
          end_date: <span>{expirationDate}</span>,
          more_vert: <FiMoreVertical />,
        });
      }
    }
    return rows; 
   
  }

  // If no pools, return an empty promise with empty rows
};

// Updated displayTokenPool function
const displayTokenPool = async (pool, rows, totalRewardAmount, expirationDate,network) => {
  
    
};



export const myRewardsRows = async (pools, claimReward, network) => {
  if (pools && pools.length > 0) {
    const rows = [];
    const promises = [];  // Array to hold promises

    for (const pool of pools) {
      // Adjust timestamp if too large
      const expirationTime = pool.pool.expirationTime;
      const adjustedExpirationTime = expirationTime > 1e12 ? expirationTime / 1000 : expirationTime;

      // Convert to a human-readable date
      const expirationDate = new Date(adjustedExpirationTime * 1000).toLocaleDateString();


      if (pool.pool.tokenContract) {
        const {symbol} = await getAccountInfo(pool.pool.tokenContract, pool.address)
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {symbol}</span>,
          end_date: <span>{expirationDate}</span>,
          claim_button: <FilledButton btnText={"Claim Reward"} handleClick={() => claimReward(pool)} />,
        });
        

      }else{
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {network?.nativeCurrency?.symbol}</span>,
          end_date: <span>{expirationDate}</span>,
          claim_button: <FilledButton btnText={"Claim Reward"} handleClick={() => claimReward(pool)} />,
        });
      }
      
      
    }

    console.log(rows);
    return rows;
  }
};

export const myEndedRewardsRows = async (pools, claimReward, network) => {
  if (pools && pools.length > 0) {
    const rows = [];
    const promises = [];  // Array to hold promises

    for (const pool of pools) {
      // Adjust timestamp if too large
      const expirationTime = pool.pool.expirationTime;
      const adjustedExpirationTime = expirationTime > 1e12 ? expirationTime / 1000 : expirationTime;

      // Convert to a human-readable date
      const expirationDate = new Date(adjustedExpirationTime * 1000).toLocaleDateString();


      if (pool.pool.tokenContract) {
        const {symbol} = await getAccountInfo(pool.pool.tokenContract, pool.address)
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {symbol}</span>,
          end_date: <span>{expirationDate}</span>,
        });
        

      }else{
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {network?.nativeCurrency?.symbol}</span>,
          end_date: <span>{expirationDate}</span>,
        });
      }
      
      
    }

    console.log(rows);
    return rows;
  }
};
export const myClaimedRewardsRows = async (pools, claimReward, network) => {
  if (pools && pools.length > 0) {
    const rows = [];
    const promises = [];  // Array to hold promises

    for (const pool of pools) {
      // Adjust timestamp if too large
      const expirationTime = pool.pool.expirationTime;
      const adjustedExpirationTime = expirationTime > 1e12 ? expirationTime / 1000 : expirationTime;

      // Convert to a human-readable date
      const expirationDate = new Date(adjustedExpirationTime * 1000).toLocaleDateString();


      if (pool.pool.tokenContract) {
        const {symbol} = await getAccountInfo(pool.pool.tokenContract, pool.address)
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {symbol}</span>,
          end_date: <span>{expirationDate}</span>,
          tx_hash:<>
          <span>{formatTransactionHash(pool?.txhash)}</span>
          <FiCopy
            cursor={"pointer"}
            onClick={() => {
              copyToClipboard(pool?.txhash);
            }}
          />
          </> 
        });
        

      }else{
        rows.push({
          pool_name: <span>{pool.pool.poolName}</span>,
          unique_identifier: <span>{pool.pool.salt}</span>,
          token_share: <span>{pool.rewardAmount ? Number(pool.rewardAmount).toFixed(5) : '0.00000'} {network?.nativeCurrency?.symbol}</span>,
          end_date: <span>{expirationDate}</span>,
          tx_hash:<>
          <span>{formatTransactionHash(pool?.txhash)}</span>
          <FiCopy
            cursor={"pointer"}
            onClick={() => {
              copyToClipboard(pool?.txhash);
            }}
          />
          </>         });
      }
      
      
    }

    console.log(rows);
    return rows;
  }
};


