import React, { useState } from "react";
import FormOne from "./Sections/FormOne";
import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import FormTwo from "./Sections/FormTwo";
import FormThree from "./Sections/FormThree";
import SuccessCard from "./Sections/SuccessCard";
import AuthHeader from "../../reusables/Navs/AuthHeader";
import { useSelector } from "react-redux";

export const CreatePoolPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 100%;
  gap: 15px;

  .blob-1 {
    position: absolute;
    bottom: 0px;
    left: -150px;
    opacity: 0.7;
    z-index: 1;
    width: 1323px;
    height: 100%;
  }
`;

const steps = [
  { label: "Pool Details" },
  { label: "Payment Details" },
  { label: "Authorized Payment" },
];

const NavigateSection = styled.a`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  color: ${({ theme }) => theme.white};
  width: 70%;

  &:hover {
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 968px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 6.51px;
    border-radius: 12px;
    color: ${({ theme }) => theme.greenLight};
    font-size: 24px;
    background: #ffffff1f;
  }
`;

const CreatePool = ({ currentTheme }) => {
  const [activeStep, setActiveStep] = useState(0);
  const supportedNetworks = useSelector(
    (state) => state.networks.supportedNetworks
  );

  return (
    <>
      <AuthHeader hideNavBar={true} />
      <CreatePoolPageContentWrapper>
        <img
          src="/assets/img/SVG/blob-2.svg"
          className="blob-1"
          alt="blob-1"
          style={{ zIndex: 1 }}
        />
        <div
          style={{
            zIndex: "100",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <NavigateSection href="/airdrop-pool/private">
            <MdKeyboardArrowLeft />
            <span>Create Pool</span>
          </NavigateSection>
          {activeStep === 0 && (
            <FormOne
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 1 && (
            <FormTwo
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              supportedNetworks={supportedNetworks}
              currentTheme={currentTheme}
            />
          )}
          {activeStep === 2 && (
            <FormThree
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 3 && <SuccessCard setActiveStep={setActiveStep} />}
        </div>
      </CreatePoolPageContentWrapper>
    </>
  );
};

export default CreatePool;
