import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";

import {
  ButtonContainer2,
  Container,
  DetailCard,
  DetailsContainer,
  FilledButton,
  InputContainer,
  OutlinedButton,
  Prop,
  Value,
} from "./style";
import FormCard from "../../../reusables/cards/FormCard";
import StepperComponent from "../../../reusables/Steppers";
import {
  createRewardPool,
  createRewardPoolNative,
} from "../../../../methods/contracts/rewardPool";
import {
  fillCreatePoolForm,
  saveRewardPoolTxApi,
} from "../../../../redux/actions/poolAction";
import web3 from "web3";
import ErrorMessage from "../../../reusables/MessagePopups/ErrorMessage";

const FormThree = ({ activeStep, steps, setActiveStep }) => {
  const poolInfo = useSelector((state) => state.PoolReducer);
  const user = useSelector((state) => state.persistedStore);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState();

  const mapAddressesToRewards = (addresses, amounts) => {
    if (addresses.length !== amounts.length) {
      throw new Error(
        "Addresses and amounts arrays must be of the same length"
      );
    }

    return addresses.map((address, index) => ({
      eligibleAddress: address,
      rewardAmount: amounts[index],
    }));
  };

  const handleConfirm = async (e) => {
    const Web3 = new web3(window.ethereum);
    try {
      e.preventDefault();
      console.log(poolInfo?.poolEndDate);
      const poolEndDate = new Date("November 30, 2024");
      const duration = poolEndDate.getTime() - Date.now();
      // const duration = new Date(poolInfo?.poolEndDate) - Date.now();
      // Convert recipient amounts to Wei
      const recipientAmountsInWei = poolInfo?.recipientAmounts.map((amount) =>
        Web3.utils.toWei(amount.toString(), "ether")
      );
      const poolIdentifierBytes32 = ethers.encodeBytes32String(
        poolInfo?.poolIdentifier
      );

      // Total amount in Wei
      const totalAmountInWei = recipientAmountsInWei.reduce(
        (acc, curr) => acc + BigInt(curr),
        BigInt(0)
      );
      console.log("called this function", poolInfo?.poolToken?.decimal);
      let pool, tx;

      if (poolInfo?.isNativeToken) {
        const result = await createRewardPoolNative(
          user?.address,
          user?.network,
          user?.network.nativeCurrency.decimals,
          poolInfo?.recipientAddresses,
          recipientAmountsInWei,
          poolIdentifierBytes32,
          duration,
          poolInfo?.poolName,
          Date.now(),
          totalAmountInWei
        );
        pool = result.rewardPool;
        tx = result.tx;
      } else {
        const result = await createRewardPool(
          user?.address,
          user?.network,
          poolInfo?.poolToken?.decimal,
          poolInfo?.recipientAddresses,
          poolInfo?.recipientAmounts,
          recipientAmountsInWei,
          poolIdentifierBytes32,
          duration,
          poolInfo?.poolName,
          Date.now(),
          totalAmountInWei,
          poolInfo.poolToken.tokenAddress
        );
        pool = result.rewardPool;
        tx = result.tx;
      }
      console.log(user?.networkId);
      const poolObjectToSave = {
        poolName: poolInfo?.poolName,
        salt: poolInfo?.poolIdentifier,
        creatorAddress: user?.address,
        poolAddress: pool.poolAddress,
        tokenContract: poolInfo?.poolToken?.tokenAddress,
        chainId: user?.networkId.toString(),
        expirationTime: Number(pool.expirationTime),
        chainId: user?.networkId.toString(),
        eligibleAddresses: mapAddressesToRewards(
          poolInfo?.recipientAddresses,
          poolInfo?.recipientAmounts
        ),
        blockNumber: Number(tx.blockNumber),
      };
      console.log(poolObjectToSave);

      saveRewardPoolTxApi(poolObjectToSave);
      dispatch(
        fillCreatePoolForm({
          props: ["createdPoolTx"],
          value: tx,
        })
      );
      setActiveStep(3);
    } catch (err) {
      setErrors([err.message]);
    }
  };

  const handlePrevStep = (e) => {
    e.preventDefault();
    setActiveStep(1);
  };

  return (
    <FormCard>
      <StepperComponent activeStep={activeStep} steps={steps} />
      <InputContainer>
        <DetailsContainer>
          <DetailCard>
            <Prop>Pool Name:</Prop>
            <Value>{poolInfo?.poolName}</Value>
          </DetailCard>
          <DetailCard>
            <Prop>Unique Identifier:</Prop>
            <Value>{poolInfo?.poolIdentifier}</Value>
          </DetailCard>
        </DetailsContainer>
        <DetailsContainer>
          <DetailCard>
            <Prop>Description:</Prop>
            <Value>{poolInfo?.poolDescription}</Value>
          </DetailCard>
          <DetailCard>
            <Prop>End Date:</Prop>
            <Value>{poolInfo?.poolEndDate}</Value>
          </DetailCard>
        </DetailsContainer>
        <DetailsContainer>
          <DetailCard>
            <Prop>Total Addresses:</Prop>
            <Value>{poolInfo?.recipientAddresses?.length}</Value>
          </DetailCard>
          <DetailCard>
            <Prop>Amount To Share:</Prop>
            <Value>
              {Array.isArray(poolInfo?.recipientAmounts)
                ? poolInfo.recipientAmounts.reduce(
                    (acc, curr) => acc + Number(curr),
                    0
                  )
                : 0}{" "}
              {poolInfo?.isNativeToken
                ? user?.network?.nativeCurrency?.symbol
                : poolInfo?.poolToken?.symbol}
            </Value>
          </DetailCard>
        </DetailsContainer>
        <DetailsContainer>
          <DetailCard>
            <Prop>Balance:</Prop>
            <Value>
              {poolInfo?.isNativeToken
                ? poolInfo?.walletBalance
                : poolInfo?.poolToken?.balance}{" "}
              {poolInfo?.isNativeToken
                ? user?.network?.nativeCurrency?.symbol
                : poolInfo?.poolToken?.symbol}
            </Value>
          </DetailCard>
        </DetailsContainer>
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "red",
                }}
              >
                {errors?.map((error) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
      </InputContainer>
      <ButtonContainer2>
        <OutlinedButton onClick={handlePrevStep}>Back</OutlinedButton>
        <FilledButton onClick={handleConfirm}>Confirm</FilledButton>
      </ButtonContainer2>
    </FormCard>
  );
};

export default FormThree;
