import React, { useState } from "react";
import { IoMoonOutline } from "react-icons/io5";
import { BsSun } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { useTheme } from "styled-components";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  NavItems,
  ThemeToggleContainer,
  MobileNavItems,
  HamburgerIcon,
  HeaderButton,
} from "./styles";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../../redux/actions";
import modalConstants from "../modal/modalConstants";
import { RouteSwitchContainer } from "../../LandingPage/styles";
import { useLocation, useNavigate } from "react-router-dom";

const AuthHeader = ({
  currentTheme,
  themeToggler,
  address,
  network,
  handleDisconnect,
  hideNavBar,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderContainer>
      <HeaderLeft>
        <a href="/">
          <img
            // style={{ width: 120 }}
            src={
              currentTheme === "light"
                ? "/assets/img/PNG/mitterpay_logo_light.png"
                : "/assets/img/PNG/mitter_logo.png"
            }
            alt="logo"
          />
        </a>
      </HeaderLeft>
      <HeaderRight>
        {!hideNavBar && (
          <NavItems>
            <HeaderButton
              onClick={() => {
                dispatch(toggleModal(modalConstants.SelectNetwork));
              }}
            >
              {network}
            </HeaderButton>
            <HeaderButton>{address}</HeaderButton>
            <HeaderButton onClick={handleDisconnect}>Disconnect</HeaderButton>
            <RouteSwitchContainer>
              <button
                className={pathname === "/multisend" && "active"}
                onClick={() => {
                  navigate("/multisend");
                }}
              >
                Multi-Sender
              </button>
              <button
                className={pathname === "/airdrop-pool/private" && "active"}
                onClick={() => {
                  navigate("/airdrop-pool/private");
                }}
              >
                Airdrop Pool
              </button>
            </RouteSwitchContainer>
            <ThemeToggleContainer>
              {currentTheme === "light" && (
                <IoMoonOutline
                  size={24}
                  color={theme.black}
                  cursor={"pointer"}
                  onClick={themeToggler}
                />
              )}
              {currentTheme === "dark" && (
                <BsSun
                  size={24}
                  color={theme.black}
                  cursor={"pointer"}
                  onClick={themeToggler}
                />
              )}
            </ThemeToggleContainer>
          </NavItems>
        )}
        <HamburgerIcon onClick={toggleMenu}>
          <FiMenu size={24} />
        </HamburgerIcon>
        {menuOpen && (
          <>
            {!hideNavBar && (
              <MobileNavItems>
                <HeaderButton>{network}</HeaderButton>
                <HeaderButton>{address}</HeaderButton>
                <HeaderButton onClick={handleDisconnect}>
                  Disconnect
                </HeaderButton>
                <RouteSwitchContainer>
                  <button
                    className={pathname === "/multisend" && "active"}
                    onClick={() => {
                      navigate("/multisend");
                    }}
                  >
                    Multi-Sender
                  </button>
                  <button
                    className={pathname === "/airdrop-pool/private" && "active"}
                    onClick={() => {
                      navigate("/airdrop-pool/private");
                    }}
                  >
                    Airdrop Pool
                  </button>
                </RouteSwitchContainer>
                <ThemeToggleContainer>
                  {currentTheme === "light" && (
                    <IoMoonOutline
                      size={24}
                      color={theme.black}
                      cursor={"pointer"}
                      onClick={themeToggler}
                    />
                  )}
                  {currentTheme === "dark" && (
                    <BsSun
                      size={24}
                      color={theme.black}
                      cursor={"pointer"}
                      onClick={themeToggler}
                    />
                  )}
                </ThemeToggleContainer>
              </MobileNavItems>
            )}
          </>
        )}
      </HeaderRight>
    </HeaderContainer>
  );
};

export default AuthHeader;
