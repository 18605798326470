import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { getAccountInfo } from "../../../methods/contracts/getBalance";
import "./styles.css";
import styled, { useTheme } from "styled-components";

const LabelValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: ${({ theme }) => theme.white};
`;

const InputSelect = ({
  options,
  value,
  onChange,
  onInputChange,
  placeholder,
  walletAddress,
  disabled,
  label,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const theme = useTheme();

  const getAddressInfo = async (value) => {
    console.log(walletAddress,value )
    let { decimals, symbol, walletBalance } = await getAccountInfo(
      value,
      walletAddress
    );
    if (value && walletBalance) {
      onChange({
        tokenAddress: value,
        symbol: symbol,
        decimal: decimals,
        balance: walletBalance.toFixed(4),
      });
    }
  };

  const handleInputChange = async (event) => {
    const value = event?.target?.value;
    setInputValue(value);
    await getAddressInfo(value);
  };

  const handleOptionSelect = (option) => {
    setInputValue(option?.tokenAddress);
    onChange(option);
    setShowDropdown(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <LabelValueContainer>
        <Label>{label}</Label>
      </LabelValueContainer>
      <div
        className="inputSelectContainer"
        style={{ opacity: disabled ? "0.5" : "1" }}
      >
        <FaSearch />
        <input
          type="text"
          className="input"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          onFocus={() => setShowDropdown(true)}
          disabled={disabled}
          style={{ color: theme.text }}
        />
        {showDropdown && options?.length > 0 && (
          <div
            className="dropdown"
            style={{
              backgroundColor: theme.body,
              borderColor: theme.greenLight,
              overflowX: "auto",
            }}
          >
            {options?.map((token) => (
              <div
                key={token?.symbol}
                className="dropdownOption"
                onClick={() => handleOptionSelect(token)}
              >
                {token?.symbol} - {token?.balance} - {token?.tokenAddress}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputSelect;
