import React, { useEffect, useState } from "react";

import {
  DetailContainer,
  DetailHeaderText,
  DetailLabel,
  DetailText,
  FilledButton2,
  TextLabel,
  TransactionHash,
  TransactionHashContainer,
  TransactionHashSection,
} from "./style";
import FormCard from "../../../reusables/cards/FormCard";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  copyToClipboard,
  formatTransactionHash,
} from "../../../../methods/helper";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import web3 from "../../../../methods/web3setup";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
const SuccessCard = ({ setActiveStep }) => {
  const poolInfo = useSelector((state) => state.PoolReducer);
  const user = useSelector((state) => state.persistedStore);
  const [createdPoolTx, setCreatedPoolTx] = useState();

  const formattedBlockNumber =
    createdPoolTx && createdPoolTx?.blockNumber?.toString();
  const formattedGasUsed =
    createdPoolTx &&
    web3?.utils?.fromWei(createdPoolTx?.effectiveGasPrice.toString(), "ether");

  useEffect(() => {
    console.log(poolInfo)
    if (poolInfo.createdPoolTx) {
      console.log(poolInfo.createdPoolTx);
      setCreatedPoolTx(poolInfo.createdPoolTx);
    }
  }, [poolInfo]);

  return (
    <FormCard>
      <Container>
        <img
          src="/assets/img/SVG/green-check.svg"
          alt="green-check"
          style={{ width: "150px" }}
        />
        <TextLabel>Pool Payment Created Successfully!</TextLabel>
        <DetailHeaderText>Transaction Details</DetailHeaderText>
        <DetailContainer>
          <DetailLabel>Hash:</DetailLabel>
          <DetailText>
            <TransactionHash
              href={`${user.network.blockExplorerUrls}${createdPoolTx?.transactionHash}`}
              target="_blank"
            >
              {formatTransactionHash(createdPoolTx?.transactionHash)}
            </TransactionHash>
          </DetailText>
        </DetailContainer>
        <DetailContainer>
          <DetailLabel>Block Number:</DetailLabel>
          <DetailText>{formattedBlockNumber}</DetailText>
        </DetailContainer>
        <DetailContainer>
          <DetailLabel>Gas Used:</DetailLabel>
          <DetailText>{formattedGasUsed}</DetailText>
        </DetailContainer>
        <TransactionHashSection>
          {/* <TextLabel>Transaction Hash:</TextLabel>
          <TransactionHashContainer>
            <span>{formatTransactionHash(createdPoolTx?.transactionHash)}</span>
            <FiCopy
              cursor={"pointer"}
              onClick={() => {
                copyToClipboard(createdPoolTx?.transactionHash);
              }}
            />
          </TransactionHashContainer> */}
          <FilledButton2 onClick={() => setActiveStep(0)}>
            Create another pool
          </FilledButton2>
        </TransactionHashSection>
      </Container>
    </FormCard>
  );
};

export default SuccessCard;
